//import React, {useState} from 'react'
import axios, {AxiosResponse} from 'axios'
import {DynamicConfig} from '../../../../../configuration/config'
//import { Query } from 'react-query'
import {ID, QueryState, Response} from '../../../../../_metronic/helpers'
import {DeviceGroup, DeviceGroupQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const DeviceGroup_URL = `${API_URL}/deviceGroups`

const deviceGroups = '/deviceGroups'

const getDeviceGroup = (
  configs: DynamicConfig,
  state: QueryState
): Promise<DeviceGroupQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${deviceGroups}/get-deviceGroups`, {
      headers: state,
    })
    .then((d: AxiosResponse<DeviceGroupQueryResponse>) => d.data)
}

const getDeviceGroupByIMEI = (
  configs: DynamicConfig,
  id: string
): Promise<DeviceGroupQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${deviceGroups}/get-deviceGroupByImei`, {
      headers: id,
    })
    .then((d: AxiosResponse<DeviceGroupQueryResponse>) => d.data)
}

const getDeviceGroupById = (
  configs: DynamicConfig,
  id: string
): Promise<DeviceGroup | undefined> => {
  return axios
    .post(`${configs.apiUrl}${deviceGroups}/get-deviceGroupById`, {Id: id})
    .then((d: AxiosResponse<DeviceGroup>) => d.data)
}

const createDeviceGroup = (
  configs: DynamicConfig,
  deviceGroup: DeviceGroup
): Promise<DeviceGroup | undefined> => {
  return axios
    .post(`${configs.apiUrl}${deviceGroups}/create-deviceGroup`, deviceGroup)
    .then((response: AxiosResponse<Response<DeviceGroup>>) => response.data)
    .then((response: Response<DeviceGroup>) => response.data)
}

const updateDeviceGroup = (
  configs: DynamicConfig,
  deviceGroup: DeviceGroup
): Promise<DeviceGroup | undefined> => {
  return axios
    .put(`${configs.apiUrl}${deviceGroups}/update-deviceGroup`, deviceGroup)
    .then((response: AxiosResponse<Response<DeviceGroup>>) => response.data)
    .then((response: Response<DeviceGroup>) => response.data)
}

const deleteDeviceGroup = (configs: DynamicConfig, id: string): Promise<void> => {
  return axios.put(`${configs.apiUrl}${deviceGroups}/delete-deviceGroup`, {Id: id}).then(() => {})
}

const deleteSelectedDeviceGroups = (configs: DynamicConfig, userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${configs.apiUrl}${deviceGroups}/${id}`))
  return axios.all(requests).then(() => {})
}

const getDeviceGroupByFilter = (
  configs: DynamicConfig,
  state: QueryState
): Promise<DeviceGroupQueryResponse> | undefined => {
  if (state.filter === undefined) return undefined
  if (!state.filter.Vol && state.filter.Vol !== 0) {
    delete state.filter.Vol;
  }
  return axios
    .post(`${configs.apiUrl}${deviceGroups}/get-deviceGroupByFilter`, state.filter, {
      headers: {
        currentPage: state.currentPage,
        pageSize: state.pageSize,
      },
    })
    .then((d: AxiosResponse<DeviceGroupQueryResponse>) => d.data)
}

const controlVolumeDeviceGroupInfo = (
  configs: DynamicConfig,
  Id: string,
  volume: number,
  onSuccess: any,
  onError: any
): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${deviceGroups}/control-volumeDeviceInGroup`, {
      Id: Id,
      Volume: volume,
    })
    .then(() => {})
    .then((res) => onSuccess(res))
    .catch((e) => onError(e.response.data))
}

const controlBitrateDeviceGroupInfo = (
  configs: DynamicConfig,
  Bitrate: number,
  onSuccess: any,
  onError: any
): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${deviceGroups}/control-bitrateDeviceInGroup`, {
      // Id: Id,
      Bitrate: Bitrate,
    })
    .then(() => {})
    .then((res) => onSuccess(res))
    .catch((e) => onError(e.response.data))
}

const checkDeleteDeviceGroup = (
  configs: DynamicConfig,
  Id: string,
  onSuccess: any,
  onError: any
): Promise<void> => {
  return axios
    .put(`${configs.apiUrl}${deviceGroups}/check-delete-deviceGroup`, {Id: Id})
    .then((res) => onSuccess(res))
    .catch((e) => onError(Id, e.response.data))
}

const ExportUserGroups = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(
      `${configs.apiUrl}${deviceGroups}/export-deviceGroup`,
      state.filter || {
        FromTime: null,
        ToTime: null,
        DeviceGroupName: '',
        Info: '',
        CreateByUser: '',
        ImeiMaster: '',
      },
      {responseType: 'blob'}
    )
    .then((d: AxiosResponse<Blob>) => d.data)
}

export {
  getDeviceGroup,
  ExportUserGroups,
  getDeviceGroupById,
  getDeviceGroupByIMEI,
  deleteDeviceGroup,
  deleteSelectedDeviceGroups,
  createDeviceGroup,
  updateDeviceGroup,
  getDeviceGroupByFilter,
  checkDeleteDeviceGroup,
  controlVolumeDeviceGroupInfo,
  controlBitrateDeviceGroupInfo,
}
