import { FC, useState, createContext, useContext, useMemo, SetStateAction, Dispatch } from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
} from '../../../../../_metronic/helpers'
import { useQueryResponse, useQueryResponseData } from './QueryResponseProviderNKBDT'

type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void

  isAllSelected: boolean
  disabled: boolean
}

const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },

  isAllSelected: false,
  disabled: false,
}

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProviderNKBDT: FC = ({ children }) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const { isLoading } = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  return (
    <ListViewContext.Provider
      value={{
        selected,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },

      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export { ListViewProviderNKBDT, useListView }
