import {DeleteTwoTone, PlusSquareTwoTone} from '@ant-design/icons'
import {Form, InputNumber, Modal} from 'antd'
import {FC, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {useAuth} from '../../../../modules/auth'
import {getDeviceById, updateDeviceInfo} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'

type Props = {
  isModalSpeakerOpen: boolean
  handleCancel: any
  rowSelected: any
  idDevice: string
}
const SpeakerModal: FC<Props> = ({isModalSpeakerOpen, handleCancel, rowSelected, idDevice}) => {
  const [, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const {auth} = useAuth()
  const {refetch} = useQueryResponse()
  //   const [isModalSpeakerOpen, setIsModalSpeakerOpen] = useState<boolean>(isModalSpeakerOpen)
  //   const [inputSpeakerValue, setInputSpeakerValue] = useState(0)
  const [listSpeaker, setListSpeaker] = useState<Array<any>>([{Direction: 0}])

  const handleOKChangeSpeaker = () => {
    const param: any = rowSelected
    param.Speakers = listSpeaker
    handleCancel(false)
    try {
      updateDeviceInfo(auth?.configs, param)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Cập nhật thông tin thành công',
            text: ' ',
            showConfirmButton: false,
            timer: 1500,
          })
          cancel(true)
        })
        .catch((error) =>
          Swal.fire({
            icon: 'error',
            title:
              typeof error.response.data != 'object'
                ? error.response.data
                : 'Cập nhật thông tin thất bại',
            text: ' ',
            confirmButtonText: 'Đóng lại',
          })
        )
    } catch (ex) {
      console.error(ex)
    } finally {
      refetch()
      setIsModalOpen(false)
    }
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const handleCancelSpeaker = () => {
    setListSpeaker([{Direction: 0}])
    form.resetFields()
    handleCancel(false)
  }

  const addSpeaker = () => {
    setListSpeaker([...listSpeaker, {Direction: 0}])
  }

  const deleteSpeaker = (index: number) => {
    let tmp = [...listSpeaker]
    tmp.splice(index, 1)
    setListSpeaker(tmp)
  }

  const onChangeSpeaker = (newValue: any, index: number) => {
    // Kiểm tra nếu phần tử tại index không phải là null
    if (listSpeaker[index] !== null) {
      // Cập nhật mảng bằng cách giữ nguyên các phần tử khác và thay đổi phần tử tại vị trí index
      const updatedArray = listSpeaker.map((item, i) =>
        i === index ? {...item, Direction: newValue, Name: 'Loa ' + (index + 1), Power: 0} : item
      )
      // Cập nhật lại state
      setListSpeaker(updatedArray)
    }

    // setInputSpeakerValue(newValue)
  }

  useEffect(() => {
    if (idDevice) {
      getDeviceById(auth?.configs, idDevice).then((data) => {
        setListSpeaker(data.Speakers || [{Direction: 0}])
        if (data.Speakers && data.Speakers?.length > 0) {
          data.Speakers.forEach((element) => {
            form.setFieldValue(element.Name, element.Direction)
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDevice])

  return (
    <>
      <Modal
        className='nguoi-dung-body'
        title='Cấu hình loa'
        open={isModalSpeakerOpen}
        onOk={handleOKChangeSpeaker}
        okText={'Đồng ý'}
        cancelText={'Hủy bỏ'}
        onCancel={handleCancelSpeaker}
      >
        {listSpeaker &&
          listSpeaker.map((speaker, index) => (
            <Form
              name={`layout-multiple-vertical-${index}`}
              key={index}
              form={form}
              layout='vertical'
              style={{display: 'flex', justifyContent: 'start', width: '100%'}}
            >
              <Form.Item label={`Loa ${index + 1}`} name={speaker.Name} style={{width: '100%'}}>
                <InputNumber
                  style={{width: '100%'}}
                  min={0}
                  max={360}
                  value={speaker.Direction} // Sử dụng đúng giá trị Direction
                  onChange={(value) => onChangeSpeaker(value, index)}
                  placeholder='Nhập hướng loa'
                />
              </Form.Item>
              {listSpeaker.length > 1 && (
                <DeleteTwoTone
                  onClick={() => deleteSpeaker(index)}
                  twoToneColor='#E43D30'
                  style={{fontSize: '24px'}}
                />
              )}
            </Form>
          ))}

        <PlusSquareTwoTone
          onClick={addSpeaker}
          title='Thêm loa'
          style={{display: 'flex', justifyContent: 'flex-end', fontSize: '24px'}}
        />
      </Modal>
    </>
  )
}

export default SpeakerModal
