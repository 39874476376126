import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {CloseOutlined} from '@ant-design/icons'

const MaDinhDanhEditModalHeader = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      {itemIdForUpdate === '0' && <h2 className='fw-bolder'>Thêm mới mã định danh</h2>}
      {itemIdForUpdate && itemIdForUpdate?.length > 1 && (
        <h2 className='fw-bolder'>Cập nhật mã định danh</h2>
      )}
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate('')}
        style={{cursor: 'pointer'}}
      >
        <CloseOutlined style={{fontSize: '16px'}} />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {MaDinhDanhEditModalHeader}
