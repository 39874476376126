import { RoleMoreModalForm } from './RoleMoreModalForm'
import { useListView } from '../core/ListViewProvider'

const RoleMoreModalFormWrapper = () => {
  const { selectedObjectTable } = useListView()

  return <RoleMoreModalForm roles={selectedObjectTable.Roles || []} />

}

export { RoleMoreModalFormWrapper }
