import React from 'react'
import TimeSlider from 'react-input-slider'
interface ChangeVolumeProps {
  volume: number
  handleVolumeChange: any
}

const ChangeVolume = ({volume, handleVolumeChange}: ChangeVolumeProps) => {
  return (
    <div className='div-volume'>
      <TimeSlider
        axis='x'
        xmax={1}
        xstep={0.1}
        xmin={0}
        x={volume}
        onChange={handleVolumeChange}
        styles={{
          track: {
            backgroundColor: '#9899AC',
            height: '5px',
            width: '70px',
          },
          active: {
            backgroundColor: '#1e2ec0',
            height: '5px',
          },
          thumb: {
            width: '9px',
            height: '9px',
            backgroundColor: '#1e2ec0',
          },
        }}
      />
    </div>
  )
}

export default ChangeVolume
