import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {getDistrictByCityId, getWardByDistrictId} from '../../../diachi/diachi-list/core/_requests'
import {DeviceInfo, District, Ward} from '../../../MAP_New2/core/_models'
import {
  getListDeviceGroupByUsername,
  getListManufacture,
  updateDeviceInfo,
} from '../../../MAP_New2/core/_requests'
import {DeviceGroup} from '../../../nguoidung/nguoidung-list/core/_modelsDeviceGroup'
import {Manufacture} from '../../../nhacungcap/nhacungcap-list/core/_models'
import {useListViewBDT} from '../core/ListViewProviderBDT'
import {useQueryResponse} from '../core/QueryResponseProviderBDT'

type Props = {
  isLoading: boolean
  deviceInfo: DeviceInfo
}

const editDeviceInfoSchema = Yup.object().shape({
  Info: Yup.string()
    .trim()
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự')
    .required('Tên thiết bị không được để trống'),
  Imei: Yup.string().required('Mã IMEI không được để trống'),
  // .length(15, 'Mã IMEI phải là 15 ký tự'),
  // .matches(/^\d{15}$/, 'Mã IMEI phải là dãy 15 số'),
  Longitude: Yup.number()
    .min(
      102.13596451123415,
      'Giá trị kinh độ chỉ hỗ trợ địa điểm ở Việt Nam và một số vùng lân cận'
    )
    .max(
      117.15006168913696,
      'Giá trị kinh độ chỉ hỗ trợ địa điểm ở Việt Nam và một số vùng lân cận'
    )
    .required('Kinh độ không hợp lệ'),
  Latitude: Yup.number()
    .min(7.5820688268599925, 'Giá trị vĩ độ chỉ hỗ trợ địa điểm ở Việt Nam và một số vùng lân cận')
    .max(23.401362513253336, 'Giá trị vĩ độ chỉ hỗ trợ địa điểm ở Việt Nam và một số vùng lân cận')
    .required('Vĩ độ không hợp lệ'),
  CustomerPhone: Yup.string()
    .min(10, 'Tối thiểu 10 số')
    .max(11, 'Tối đa 11 số')
    .matches(/^0\d{9,10}$/, 'Số điện thoại không đúng định dạng'),
})

const DeviceEditModalForm: FC<Props> = ({deviceInfo, isLoading}) => {
  const {setItemIdForUpdateLed, cityIdSelected} = useListViewBDT()
  const {refetch} = useQueryResponse()
  const {auth, currentUser} = useAuth()
  const [listWard, setListWard] = useState(new Array<Ward>())
  const [district, setDistrict] = useState<string>(deviceInfo.Address?.DistrictName || '')
  const [ward, setWard] = useState(deviceInfo.Address?.WardName || '')
  const [listManufacture, setListManufacture] = useState<Array<Manufacture>>([])
  const [listDeviceGroup, setListDeviceGroup] = useState<Array<DeviceGroup>>([])
  const [lstDistrict, setLstDistrict] = useState<Array<District>>([])
  const [iTime, setITime] = useState<any>() // Thời gian lắp đặt
  const [wTime, setWTime] = useState<any>() // Thời gian bảo hành

  useEffect(() => {
    getListManufacture(auth?.configs).then((data) => {
      setListManufacture(data.Data || [])
    })
    getListDeviceGroupByUsername(auth?.configs, currentUser?.UserName || '').then((data) => {
      setListDeviceGroup(data.Data || [])
    })

    getDistrictByCityId(auth?.configs, cityIdSelected || '').then((data) => {
      setLstDistrict(data)
      let dId = data[0].DistrictId
      if (deviceInfo.Address?.WardName) {
        let arr = data.filter(function (g: District) {
          return g.DistrictName === district
        })
        if (arr.length > 0) dId = arr[0].DistrictId
      }
      getWardByDistrictId(auth?.configs, cityIdSelected || '', dId || '').then((data) => {
        setListWard(data)
      })
    })

    let iTimeTmp = ''
    if (deviceInfo.InitTime?.toString() !== '2000-01-01T00:00:00Z')
      //deviceInfo.Id !== '0' &&
      iTimeTmp = deviceInfo.InitTime?.toString().split('T')[0] || ''
    setITime(iTimeTmp)
    let wTimeTmp = ''
    if (deviceInfo.WarrantyTime?.toString() !== '2000-01-01T00:00:00Z')
      //deviceInfo.Id !== '0' &&
      wTimeTmp = deviceInfo.WarrantyTime?.toString().split('T')[0] || ''
    setWTime(wTimeTmp)
  }, [])

  const deviceInfoForEdit = {
    Imei: deviceInfo.Imei || '',
    GroupID: deviceInfo.GroupID || '',
    Info: deviceInfo.Info || '',
    DeviceType: deviceInfo.DeviceType || 0,
    Longitude: deviceInfo.Longitude || 0,
    Latitude: deviceInfo.Latitude || 0,
    CityName: deviceInfo.Address?.CityName,
    DistrictName: deviceInfo.Address?.DistrictName,
    WardName: deviceInfo.Address?.WardName || '',
    ManufactureId: deviceInfo.ManufactureId,
    InitTime: deviceInfo.InitTime,
    WarrantyTime: deviceInfo.WarrantyTime,
    ServiceType: deviceInfo.ServiceType,
    CustomerPhone: deviceInfo.CustomerPhone || '',
  }

  const [deviceInfoEdit] = useState<DeviceInfo>({
    ...deviceInfo,
  })

  const getLstWard = (districtIndex: number) => {
    setDistrict(lstDistrict[districtIndex].DistrictName || '')
    getWardByDistrictId(
      auth?.configs,
      cityIdSelected || '',
      lstDistrict[districtIndex].DistrictId || ''
    ).then((data) => {
      setListWard(data)
      setWard(data[0].WardName || '')
    })
  }

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdateLed('')
    refetch()
  }

  const formik = useFormik({
    initialValues: deviceInfoForEdit,
    validationSchema: editDeviceInfoSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      deviceInfoEdit.Imei = values.Imei
      deviceInfoEdit.GroupID = values.GroupID
      if (deviceInfoForEdit.GroupID === '' && listDeviceGroup)
        deviceInfoEdit.GroupID = listDeviceGroup[0].Id
      deviceInfoEdit.Info = values.Info
      deviceInfoEdit.DeviceType = values.DeviceType
      deviceInfoEdit.Longitude = values.Longitude
      deviceInfoEdit.Latitude = values.Latitude
      deviceInfoEdit.Address = {
        CityName: values.CityName,
        DistrictName: district,
        WardName: ward,
      }

      deviceInfoEdit.ManufactureId = values.ManufactureId
      if (!values.ManufactureId && listManufacture)
        deviceInfoEdit.ManufactureId = listManufacture[0].Id
      if (iTime !== '') deviceInfoEdit.InitTime = new Date(iTime)
      if (wTime !== '') deviceInfoEdit.WarrantyTime = new Date(wTime)
      deviceInfoEdit.ServiceType = parseInt(values.ServiceType?.toString() || '0')
      deviceInfoEdit.CustomerPhone = values.CustomerPhone

      try {
        await updateDeviceInfo(auth?.configs, deviceInfoEdit)
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Cập nhật thông tin thành công',
              text: ' ',
              showConfirmButton: false,
              timer: 1500,
            })
            setSubmitting(true)
            cancel(true)
          })
          .catch((error) =>
            Swal.fire({
              icon: 'error',
              title:
                typeof error.response.data != 'object'
                  ? error.response.data
                  : 'Cập nhật thông tin thất bại',
              text: ' ',
              confirmButtonText: 'Đóng lại',
            })
          )
      } catch (ex) {
        console.error(ex)
      } finally {
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <h2 style={{color: '#A1A9C7', fontSize: 14, fontWeight: 600, lineHeight: '17px'}}>
            THÔNG TIN THIẾT BỊ
          </h2>
          {/* begin::Input group */}
          <div className='row mt-5'>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Tên thiết bị</label>
              <input
                placeholder='Tên thiết bị'
                {...formik.getFieldProps('Info')}
                type='text'
                name='Info'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Info && formik.errors.Info},
                  {
                    'is-valid': formik.touched.Info && !formik.errors.Info,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.Info && formik.errors.Info && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Info}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Label */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='required fw-bold fs-6 mb-2'>IMEI</label>
              <input
                placeholder='IMEI'
                {...formik.getFieldProps('Imei')}
                type='string'
                name='Imei'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Imei && formik.errors.Imei},
                  {
                    'is-valid': formik.touched.Imei && !formik.errors.Imei,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.Imei && formik.errors.Imei && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Imei}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Nhà cung cấp</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('ManufactureId')}
              >
                {listManufacture?.map((m: Manufacture) => (
                  <option title={m.Info} key={m.Id} value={m.Id}>
                    {m.Info?.slice(0, 30)}
                  </option>
                ))}
              </select>
              {/* end::Label */}
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='row mt-5'>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Loại thiết bị</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('DeviceType')}
              >
                <option value='0'>Cụm thu phát thanh ứng dụng CNTT-VT</option>
                <option value='1'>Bộ số hóa truyền thanh</option>
                <option value='2'>Bảng điện tử ứng dụng CNTT-VT</option>
                <option value='3'>Cụm thu FM</option>
                <option value='4'>Bảng LED</option>
                <option value='5'>Bộ thu tích hợp tự động</option>
                <option value='6'>Bộ thu-phát tích hợp tự động</option>
              </select>
              {/* end::Label */}
            </div>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Kinh độ</label>
              <input
                placeholder='Kinh độ'
                {...formik.getFieldProps('Longitude')}
                type='number'
                name='Longitude'
                onClick={(event) => event.currentTarget.select()}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Longitude && formik.errors.Longitude},
                  {
                    'is-valid': formik.touched.Longitude && !formik.errors.Longitude,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.Longitude && formik.errors.Longitude && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Longitude}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Label */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='required fw-bold fs-6 mb-2'>Vĩ độ</label>
              <input
                placeholder='Vĩ độ'
                {...formik.getFieldProps('Latitude')}
                type='number'
                name='Latitude'
                onClick={(event) => event.currentTarget.select()}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Latitude && formik.errors.Latitude},
                  {
                    'is-valid': formik.touched.Latitude && !formik.errors.Latitude,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.Latitude && formik.errors.Latitude && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Latitude}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='row mt-5'>
            {listDeviceGroup.length > 0 && (
              <div className='col-4'>
                {/* begin::Input */}
                <label className='fw-bold fs-6 mb-2'>Địa bàn phát</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='two-step'
                  data-hide-search='true'
                  {...formik.getFieldProps('GroupID')}
                >
                  {listDeviceGroup?.map((deviceGroup: DeviceGroup) => (
                    <option key={deviceGroup.Id} value={deviceGroup.Id}>
                      {deviceGroup.DeviceGroupName}
                    </option>
                  ))}
                </select>
                {/* end::Input */}
              </div>
            )}

            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Thời gian lắp đặt</label>
              <input
                // {...formik.getFieldProps('InitTime')}
                type='date'
                name='InitTime'
                value={iTime}
                onChange={(event) => setITime(event.target.value)}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {/* end::Input */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Thời hạn bảo hành</label>
              <input
                // {...formik.getFieldProps('WarrantyTime')}
                type='date'
                name='WarrantyTime'
                value={wTime}
                onChange={(event) => setWTime(event.target.value)}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {/* end::Input */}
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Gói dịch vụ</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('ServiceType')}
              >
                <option value='0'>Tiêu chuẩn</option>
                <option value='1'>Nâng cao</option>
              </select>
              {/* end::Input */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Số điện thoại</label>
              <input
                placeholder='Số điện thoại'
                {...formik.getFieldProps('CustomerPhone')}
                type='string'
                name='CustomerPhone'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.CustomerPhone && formik.errors.CustomerPhone},
                  {
                    'is-valid': formik.touched.CustomerPhone && !formik.errors.CustomerPhone,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.CustomerPhone && formik.errors.CustomerPhone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.CustomerPhone}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
          </div>
          {/* end::Input group */}
          <div style={{display: 'flex'}}>
            <label className='fw-bold fs-6 mb-2' style={{marginTop: '2px'}}></label>
            <hr style={{flex: 1}} />
          </div>

          <h2 style={{color: '#A1A9C7', fontSize: 14, fontWeight: 600, lineHeight: '17px'}}>
            VỊ TRÍ
          </h2>
          {/* begin::Input group */}
          <div className='row mb-5 mt-5'>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Tỉnh</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                disabled
                {...formik.getFieldProps('CityName')}
              >
                <option value={deviceInfoForEdit.CityName}>{deviceInfoForEdit.CityName}</option>
              </select>
              {/* end::Label */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Huyện</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('DistrictName')}
                value={district}
                onChange={(event) => getLstWard(event.target.options.selectedIndex)}
              >
                {lstDistrict?.map((district: District) => (
                  <option key={district.DistrictId} value={district.DistrictName}>
                    {district.DistrictName}
                  </option>
                ))}
              </select>
              {/* end::Input */}
              {formik.touched.DistrictName && formik.errors.DistrictName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.DistrictName}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Xã</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('WardName')}
                value={ward}
                onChange={(event) => setWard(event.target.value)}
              >
                {listWard?.map((ward: Ward, i) => (
                  <option key={i} value={ward.WardName}>
                    {ward.WardName}
                  </option>
                ))}
              </select>
              {/* end::Label */}
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center '>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-outline-primary me-3 cancel'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Hủy bỏ
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label fw-b'>Cập nhật</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Vui lòng đợi...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export {DeviceEditModalForm}
