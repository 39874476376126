import { useListView } from '../../core/ListViewProvider'
import { ListToolbar } from './ListToolbar'
import { ListGrouping } from './ListGrouping'

const ListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* <UsersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-title'>
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative my-1'>
          Quản lý nhóm quyền
        </div>
        {/* end::Search */}
      </div>
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ListGrouping /> : <ListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { ListHeader }
