import axios, {AxiosResponse} from 'axios'
import {QueryState, Response} from '../../../../../_metronic/helpers'
import {DynamicConfig} from '../../../../../configuration/config'
import {FileResponse} from '../../../MAP_New2/core/_models'
import {NewsPapersQueryResponse, PingState, StreamingInfo} from './_models'

const uploadFile = '/uploadFile'
const meetrtc = '/meeting-rtc'

const getStreamingUByFilter = (
  configs: DynamicConfig,
  state: QueryState
): Promise<NewsPapersQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${meetrtc}/get-meeting-by-filter`,
      state.filter || {
        CallId: '',
        UserId: '',
        StateLst: [],
      },
      {
        headers: {
          currentPage: state.currentPage,
          pageSize: state.pageSize,
        },
      }
    )
    .then((d: AxiosResponse<NewsPapersQueryResponse>) => d.data)
}

const createStreamingUrlGroup = (
  configs: DynamicConfig,
  streamings: StreamingInfo
): Promise<StreamingInfo | undefined> => {
  return axios
    .post(`${configs.apiUrl}${meetrtc}/create-streaming`, streamings)
    .then((response: AxiosResponse<Response<StreamingInfo>>) => response.data)
    .then((response: Response<StreamingInfo>) => response.data)
}

const uploadFileBlob = (configs: DynamicConfig, formData: FormData): Promise<FileResponse> => {
  return axios
    .post(`${configs.apiUrl}${uploadFile}/uploadFile`, formData)
    .then((response: AxiosResponse<FileResponse>) => response.data)
}

const recordMeeting = (configs: DynamicConfig, formData: FormData) => {
  return axios.post(`${configs.apiUrl}/meeting-recording`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

const liveMeeting = (configs: DynamicConfig, formData: FormData) => {
  return axios.post(`${configs.apiUrl}/meeting-recording/live-stream`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

const deleteStreaming = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios.put(`${configs.apiUrl}${meetrtc}/delete-streaming`, {Id: Id}).then(() => {})
}

const stopStreaming = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${meetrtc}/stop-streaming`, {
      CallId: Id,
    })
    .then(() => {})
}

const pingStreamState = (configs: DynamicConfig, payload: PingState): Promise<void> => {
  return axios.post(`${configs.apiUrl}${meetrtc}/ping-meetingState`, payload).then(() => {})
}

const leaveMeetingCall = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios.post(`${configs.apiUrl}${meetrtc}/leave-streaming`, {CallId: Id}).then(() => {})
}

const joinStreaming = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios.post(`${configs.apiUrl}${meetrtc}/join-streaming`, {CallId: Id}).then(() => {})
}

const ExportStreaming = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(
      `${configs.apiUrl}${meetrtc}/export-streaming`,
      state.filter || {
        FromTime: null,
        ToTime: null,
        Name: '',
        Info: '',
        CreateByUser: '',
      },
      {responseType: 'blob'}
    )
    .then((d: AxiosResponse<Blob>) => d.data)
}

export {
  createStreamingUrlGroup,
  deleteStreaming,
  ExportStreaming,
  getStreamingUByFilter,
  joinStreaming,
  leaveMeetingCall,
  liveMeeting,
  pingStreamState,
  recordMeeting,
  stopStreaming,
  uploadFileBlob,
}
