/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {Registration} from './components/Registration'
import UsbConnect from './components/UsbConnect'
import {CheckOTP} from './components/CheckOTP'
import {getConfigs} from './core/AuthHelpers'
import TwoFactorAuth from '../../../components/TwoFactorAuth'

const AuthLayout = () => {
  const [configs, setConfigs] = useState<any>()

  useEffect(() => {
    getConfigs().then((data) => {
      return setConfigs(data)
    })

    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  const REACT_IMG_LOGO_LOGIN = process.env.REACT_APP_IMG_LOGO_LOGIN
  const REACT_TITLE_FOOTER = process.env.REACT_APP_TITLE_FOOTER

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${'/media/avatars/Login_bgr.png'})`,
        backgroundSize: 'cover',
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-column flex-column-fluid div-login'>
        {/* begin::Logo */}
        <a href='#' className='mb-8 div-logo-login'>
          <img
            alt='Logo'
            // src={REACT_IMG_LOGO_LOGIN}
            src={configs?.imgLogoLogin}
            // src='/media/avatars/Logo_login.png'
            className='logo-login'
          />
          {(configs?.textApp?.length > 0 || configs?.textLogoLogin?.length > 0) && (
            <h4 className='text-logo-login'>
              {configs?.textApp}
              <br />
              {configs?.textLogoLogin}
            </h4>
          )}
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <Outlet />
        <div className='flex-1'></div>
        <div className='footer_login row col-md-12 text-center' style={{color: '#fff'}}>
          {/* <p style={{ fontSize: '16px' }}><span className='fw-bold me-2'>{new Date().getFullYear()} &copy;</span>{REACT_TITLE_FOOTER}</p> */}
          <p style={{fontSize: '16px'}}>
            <span className='fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
            {configs?.titleFooter}
          </p>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}

      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='connect-usb' element={<UsbConnect />} />
      <Route path='check-otp' element={<CheckOTP />} />
      <Route path='google-authen' element={<TwoFactorAuth />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
