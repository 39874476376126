import {Col, Form, Input, Modal} from 'antd'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import {QUERIES} from '../../../../_metronic/helpers'
import {useQueryResponse} from '../../../pages/nguoidung/nguoidung-list/core/QueryResponseProvider'
import {changePasswordFirst} from '../../../pages/nguoidung/nguoidung-list/core/_requests'
import {useAuth} from '../core/Auth'
import {useEffect} from 'react'

const systemProperties = {
  dateTimeFormat: 'HH:mm, DD/MM/YYYY',
  dateFormat: 'DD/MM/YYYY',
  phonePattern: /^((84|0[3|2|4|6|5|7|8|9])+([0-9]{8})\b)$/g,
  emailPattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/g,
  citizenIdentifyPattern: /^[0-9]{9}$|^[0-9]{12}$/g,
  passWord: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/g,
  userName: /^^(?=.{4,20}$)/g,
}

export function ChangePasswordModal({userName, isModalOpen, handleCancel, handleOK}: any) {
  const {refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  const [form] = Form.useForm()
  const {query} = useQueryResponse()
  const {auth, configs} = useAuth()

  useEffect(() => {
    form.setFieldValue('UserName', userName)
  }, [userName])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const changePassWord = useMutation(
    (record: any) =>
      changePasswordFirst(configs, record.UserName, record.Password, record.NewPassword),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
        Swal.fire({
          icon: 'success',
          title: 'Thay đổi thành công',
          text: ' ',
          showConfirmButton: false,
          timer: 1500,
        }).then((_) => {
          cancel(true)
          handleOK()
        })
      },
      onError: () => {
        Swal.fire({
          icon: 'error',
          title: 'Thay đổi thất bại',
          text: ' ',
          confirmButtonText: 'Đóng lại',
        })
      },
    }
  )

  const handleOk = () => {
    form.validateFields().then((values) => {
      let msg = ''
      if (
        form.getFieldValue('NewPassword')?.trim() !== form.getFieldValue('ReNewPassword')?.trim()
      ) {
        msg = 'Mật khẩu xác nhận không đúng!'
      }

      if (msg) {
        Swal.fire({
          icon: 'error',
          title: msg,
          text: ' ',
          confirmButtonText: 'Đóng lại',
        })
        return
      }
      changePassWord.mutateAsync({
        UserName: form.getFieldValue('UserName'),
        Password: form.getFieldValue('Password'),
        NewPassword: form.getFieldValue('NewPassword'),
      })
      form.resetFields()
      handleCancel()
    })
  }

  return (
    <>
      <Modal
        className='nguoi-dung-body'
        centered
        open={isModalOpen}
        onOk={() => handleOk()}
        onCancel={handleCancel}
        okText='Cập nhật'
        cancelText='Hủy bỏ'
        title='Cập nhật mật khẩu'
      >
        <Col>
          <Form form={form} className={'login-form'} layout='vertical'>
            <Form.Item name='UserName' label='Tên đăng nhập'>
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name='Password'
              label='Mật khẩu cũ'
              rules={[
                {
                  required: true,
                  message: 'Mật khẩu cũ không được để trống',
                },
                {
                  pattern: systemProperties.passWord,
                  message:
                    'Mật khẩu phải tối thiểu 8 và tối đa 50 ký tự, ít nhất một ký tự hoa, một ký tự viết thường, một số và một ký tự đặc biệt',
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
            >
              <Input.Password placeholder={'Mật khẩu cũ'} />
            </Form.Item>
            <Form.Item
              name='NewPassword'
              label='Mật khẩu mới'
              rules={[
                {
                  required: true,
                  message: 'Mật khẩu mới không được để trống',
                },
                {
                  pattern: systemProperties.passWord,
                  message:
                    'Mật khẩu phải tối thiểu 8 và tối đa 50 ký tự, ít nhất một ký tự hoa, một ký tự viết thường, một số và một ký tự đặc biệt',
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
            >
              <Input.Password placeholder={'Mật khẩu mới'} />
            </Form.Item>

            <Form.Item
              name='ReNewPassword'
              label={'Xác nhận mật khẩu'}
              rules={[
                {
                  required: true,
                  message: 'Xác nhận mật khẩu không được để trống',
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
            >
              <Input.Password placeholder={'Xác nhận mật khẩu'} />
            </Form.Item>
          </Form>
        </Col>
      </Modal>
    </>
  )
}
