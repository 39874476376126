import React, {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {useAuth} from '../modules/auth/core/Auth'
import {Role} from '../modules/auth/core/_models'
import DongBoPage from '../pages/dongbo/DongBoPage'
import MaDinhDanhPage from '../pages/madinhdanh/MaDinhDanhPage'
import {MenuTestPage} from '../pages/MenuTestPage'
import NhomQuyenPage from '../pages/nhomquyen/NhomQuyenPage'
import NhomThietBiPage from '../pages/nhomthietbi/NhomThietBiPage'
import ThietBiPage from '../pages/thietbi/ThietBiPage'

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  //Bổ sung menu
  const DashboardRoute = lazy(() => import('../pages/dashboard/DashboardRoute'))
  const NhomNguoiDungPage = lazy(() => import('../pages/nhomnguoidung/NhomNguoiDungPage'))
  const NguoiDungPage = lazy(() => import('../pages/nguoidung/NguoiDungPage'))
  const DiaChiPage = lazy(() => import('../pages/diachi/DiaChiPage'))
  const BienTapPage = lazy(() => import('../pages/bientap/BienTapPage'))
  const BanTinPage = lazy(() => import('../pages/bantin/BanTinPage'))
  const PhatTrucTiepPage = lazy(() => import('../pages/phattructiep/StreamPage'))
  const CumLoaPage = lazy(() => import('../pages/baocaocumloa/CumLoaPage'))
  const BangDTPage = lazy(() => import('../pages/baocaobangdt/BangDTPage'))
  const DaiTTPage = lazy(() => import('../pages/baocaodaitt/DaiTTPage'))
  const GuiBaoCaoPage = lazy(() => import('../pages/guibaocao/GuiBaoCaoPage'))
  const ReflectionPage = lazy(() => import('../pages/phananh/FeedbackPage'))
  const HelpPage = lazy(() => import('../pages/huongdansudung/HelpPage'))
  const NhatKyPage = lazy(() => import('../pages/nhatky/NhatKyPage'))
  const NhaCungCapPage = lazy(() => import('../pages/nhacungcap/NhaCungCapPage'))
  const BaocaoPage = lazy(() => import('../pages/baocaotonghop/BaocaoPage'))
  // const HomePage = lazy(() => import('../pages/homepage/HomePage'))
  const GiamSatTrucTuyenPage = lazy(() => import('../pages/MAP_New2/GiamSatTrucTuyenPage'))
  // const HomePage = lazy(() => import('../pages/MAPv2/HomePage'))
  const MucDoUuTienPage = lazy(() => import('../pages/mucdouutien/MucDoUuTienPage'))

  const LinhVucPage = lazy(() => import('../pages/linhvuc/LinhVucPage'))
  const LoaiBanTinPage = lazy(() => import('../pages/loaibantin/LoaiBanTinPage'))
  const PhatThanhPage = lazy(() => import('../pages/baocaophatthanh/PhatThanhPage'))
  const ThongBaoPage = lazy(() => import('../pages/thongbao/ThongBaoPage'))
  const NguonLucPage = lazy(() => import('../pages/nguonluc/NguonLucPage'))
  const CauHinhPage = lazy(() => import('../pages/cauhinh/CauHinhPage'))
  const {currentUser} = useAuth()

  const VIEW_USER = currentUser?.Roles.find((item: Role) => {
    return item.Value === 3
  })
  const VIEW_LOG = currentUser?.Roles.find((item: Role) => {
    return item.Value === 6
  })
  const VIEW_USER_GROUP = currentUser?.Roles.find((item: Role) => {
    return item.Value === 7
  })
  const VIEW_DEVICE = currentUser?.Roles.find((item: Role) => {
    return item.Value === 10
  })
  const VIEW_DEVICE_GROUP = currentUser?.Roles.find((item: Role) => {
    return item.Value === 14
  })
  const VIEW_REPORT_DEVICE_STATUS = currentUser?.Roles.find((item: Role) => {
    return item.Value === 18
  })
  const VIEW_REPORT_RADIO = currentUser?.Roles.find((item: Role) => {
    return item.Value === 19
  })
  const VIEW_CONTENT_EDIT = currentUser?.Roles.find((item: Role) => {
    return item.Value === 20
  })
  const VIEW_NEWS = currentUser?.Roles.find((item: Role) => {
    return item.Value === 24
  })
  const VIEW_REPORT_COMMON = currentUser?.Roles.find((item: Role) => {
    return item.Value === 35
  })
  const VIEW_FEEDBACK = currentUser?.Roles.find((item: Role) => {
    return item.Value === 28
  })
  const VIEW_ADDRESS = currentUser?.Roles.find((item: Role) => {
    return item.Value === 30
  })
  const EDIT_MANUFACTURE = currentUser?.Roles.find((item: Role) => {
    return item.Value === 34
  })
  const VIEW_CATEGORY = currentUser?.Roles.find((item: Role) => {
    return item.Value === 37
  })
  const VIEW_ACCOUNT_INFO = currentUser?.Roles.find((item: Role) => {
    return item.Value === 2
  })
  const VIEW_NOTIFICATION = currentUser?.Roles.find((item: Role) => {
    return item.Value === 45
  })
  const VIEW_POWER = currentUser?.Roles.find((item: Role) => {
    return item.Value === 52
  })
  const VIEW_ROLE_GROUP = currentUser?.Roles.find((item: Role) => {
    return item.Value === 56
  })
  const VIEW_MA_DINH_DANH = currentUser?.Roles.find((item: Role) => {
    return item.Value === 63
  })
  const VIEW_CAU_HINH_DONG_BO = currentUser?.Roles.find((item: Role) => {
    return item.Value === 65
  })
  const ADMIN = currentUser?.Roles.find((item: Role) => {
    return item.Value === 999
  })

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />

        {(VIEW_DEVICE || ADMIN) && (
          <>
            <Route
              path='ttcs/daitruyenthanh/*'
              element={
                <SuspensedView>
                  <GiamSatTrucTuyenPage />
                </SuspensedView>
              }
            />

            <Route
              path='ttcs/giamsattructuyen/*'
              element={
                <SuspensedView>
                  <GiamSatTrucTuyenPage />
                </SuspensedView>
              }
            />
          </>
        )}

        <Route
          path='/*'
          element={
            <SuspensedView>
              <DashboardRoute />
            </SuspensedView>
          }
        />

        {(VIEW_FEEDBACK || ADMIN) && (
          <Route
            path='/phananh/*'
            element={
              <SuspensedView>
                <ReflectionPage />
              </SuspensedView>
            }
          />
        )}

        <Route
          path='/huongdansudung/*'
          element={
            <SuspensedView>
              <HelpPage />
            </SuspensedView>
          }
        />

        {(VIEW_REPORT_COMMON || ADMIN) && (
          <Route
            path='baocao/baocaotonghop/*'
            element={
              <SuspensedView>
                <BaocaoPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_REPORT_DEVICE_STATUS || ADMIN) && (
          <>
            <Route
              path='baocao/baocaocumloa/*'
              element={
                <SuspensedView>
                  <CumLoaPage />
                </SuspensedView>
              }
            />
            <Route
              path='baocao/baocaobangdt/*'
              element={
                <SuspensedView>
                  <BangDTPage />
                </SuspensedView>
              }
            />
          </>
        )}

        {(VIEW_REPORT_RADIO || ADMIN) && (
          <>
            <Route
              path='baocao/baocaodaitt/*'
              element={
                <SuspensedView>
                  <DaiTTPage />
                </SuspensedView>
              }
            />
            <Route
              path='baocao/baocaophatthanh/*'
              element={
                <SuspensedView>
                  <PhatThanhPage />
                </SuspensedView>
              }
            />
          </>
        )}

        {(VIEW_REPORT_RADIO || ADMIN) && (
          <>
            <Route
              path='baocao/guibaocao/*'
              element={
                <SuspensedView>
                  <GuiBaoCaoPage />
                </SuspensedView>
              }
            />
          </>
        )}

        {(VIEW_USER_GROUP || ADMIN) && (
          <Route
            path='quantrihethong/nhomnguoidung/*'
            element={
              <SuspensedView>
                <NhomNguoiDungPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_DEVICE_GROUP || ADMIN) && (
          <Route
            path='quantrihethong/nhomthietbi/*'
            element={
              <SuspensedView>
                <NhomThietBiPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_USER || ADMIN) && (
          <Route
            path='quantrihethong/taikhoan/*'
            element={
              <SuspensedView>
                <NguoiDungPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_LOG || ADMIN) && (
          <Route
            path='quantrihethong/nhatky/*'
            element={
              <SuspensedView>
                <NhatKyPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_ADDRESS || VIEW_CATEGORY || ADMIN) && (
          <Route
            path='quantrihethong/danhmuc/*'
            element={
              <SuspensedView>
                <DiaChiPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_ADDRESS || ADMIN) && (
          <Route
            path='quantrihethong/danhmuc/diachi/*'
            element={
              <SuspensedView>
                <DiaChiPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_CONTENT_EDIT || ADMIN) && (
          <Route
            path='ttcs/bientap/*'
            element={
              <SuspensedView>
                <BienTapPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_NEWS || ADMIN) && (
          <Route
            path='ttcs/bantin/*'
            element={
              <SuspensedView>
                <BanTinPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_NEWS || ADMIN) && (
          <Route
            path='ttcs/phattructiep/*'
            element={
              <SuspensedView>
                <PhatTrucTiepPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_DEVICE || ADMIN) && (
          <Route
            path='ttcs/thietbi/*'
            element={
              <SuspensedView>
                <ThietBiPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_POWER || ADMIN) && (
          <Route
            path='ttcs/nguonluc/*'
            element={
              <SuspensedView>
                <NguonLucPage />
              </SuspensedView>
            }
          />
        )}

        {(EDIT_MANUFACTURE || ADMIN) && (
          <Route
            path='quantrihethong/nhacungcap/*'
            element={
              <SuspensedView>
                <NhaCungCapPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_MA_DINH_DANH || ADMIN) && (
          <Route
            path='quantrihethong/danhmuc/madinhdanh/*'
            element={
              <SuspensedView>
                <MaDinhDanhPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_CATEGORY || ADMIN) && (
          <>
            <Route
              path='quantrihethong/danhmuc/mucdouutien/*'
              element={
                <SuspensedView>
                  <MucDoUuTienPage />
                </SuspensedView>
              }
            />

            <Route
              path='quantrihethong/danhmuc/linhvuc/*'
              element={
                <SuspensedView>
                  <LinhVucPage />
                </SuspensedView>
              }
            />

            <Route
              path='quantrihethong/danhmuc/loaibantin/*'
              element={
                <SuspensedView>
                  <LoaiBanTinPage />
                </SuspensedView>
              }
            />
          </>
        )}

        {(VIEW_NOTIFICATION || ADMIN) && (
          <Route
            path='thongbao/*'
            element={
              <SuspensedView>
                <ThongBaoPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_ROLE_GROUP || ADMIN) && (
          <Route
            path='quantrihethong/nhomquyen/*'
            element={
              <SuspensedView>
                <NhomQuyenPage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_ACCOUNT_INFO || ADMIN) && (
          <Route
            path='caidat/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
        )}

        {(VIEW_CAU_HINH_DONG_BO || ADMIN) && (
          <>
            <Route
              path='quantrihethong/cauhinh/cauhinhhethong/*'
              element={
                <SuspensedView>
                  <CauHinhPage />
                </SuspensedView>
              }
            />
            <Route
              path='quantrihethong/cauhinh/dongbodulieu/*'
              element={
                <SuspensedView>
                  <DongBoPage />
                </SuspensedView>
              }
            />
          </>
        )}

        {/* ===================== */}
        {/* Lazy Modules */}

        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
