// src/components/TwoFactorAuth.tsx
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../app/modules/auth'
import {getGoogleAuthen} from '../app/modules/auth/core/_requests'
import {defaultConfig} from '../configuration/config'

interface TwoFactorAuthProps {}

const TwoFactorAuth: React.FC<TwoFactorAuthProps> = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null)
  const [secret, setSecret] = useState<string | null>(null)
  const {auth, configs} = useAuth()

  // Gọi API để lấy mã QR cho Google Authenticator
  useEffect(() => {
    try {
      getGoogleAuthen(configs || defaultConfig, auth?.TaiKhoan || '').then((data: any) => {
        setQrCodeUrl(data.data || '')
        setSecret(data)
      })
    } catch (error) {
      console.error('Error setting up 2FA:', error)
    }
  }, [])

  const confirm = () => {
    document.getElementById('otp')?.click()
  }

  return (
    <div className='div-qr-code'>
      {/* <h2>Thiết lập xác thực 2 yếu tố</h2>
      <button onClick={setup2FA}>Tạo mã QR</button> */}
      <p className='color-authen'>Quét mã QR này bằng Google Authenticator:</p>
      {qrCodeUrl && (
        <div style={{width: '50%'}}>
          <img style={{width: '50%'}} src={`data:image/png;base64,${qrCodeUrl}`} alt='QR Code' />
        </div>
      )}
      <Link id='otp' to='/auth/check-otp'></Link>
      <button className='btn btn-primary mt-10' type='button' onClick={confirm}>
        Xác minh với mã
      </button>
    </div>
  )
}

export default TwoFactorAuth
