import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {CloseOutlined} from '@ant-design/icons'

const DaiTruyenThanhEditModalHeader = () => {
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      {itemIdForUpdate === '0' && <h2 className='fw-bolder'>Thêm mới đài truyền thanh</h2>}
      {itemIdForUpdate && itemIdForUpdate?.length > 1 && (
        <h2 className='fw-bolder'>Cập nhật thông tin đài truyền thanh</h2>
      )}
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate('')}
        style={{cursor: 'pointer'}}
      >
        <CloseOutlined style={{fontSize: '16px'}} />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {DaiTruyenThanhEditModalHeader}
