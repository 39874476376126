import {useQuery} from 'react-query'
import Swal from 'sweetalert2'
import {QUERIES} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {getMaDinhDanhById} from '../core/_requests'
import {MaDinhDanhInfoModalForm} from './maDinhDanhInfoModalForm'

const MaDinhDanhInfoModalFormWrapper = () => {
  const {auth} = useAuth()
  const {itemIdForInfo, setItemIdForInfo} = useListView()
  const enabledQuery: boolean = itemIdForInfo !== '0'
  const {refetch} = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForInfo('')
  }
  const {
    isLoading,
    data: maDinhDanh,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForInfo}`,
    () => {
      return getMaDinhDanhById(auth?.configs, itemIdForInfo || '')
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Không tìm thấy dữ liệu',
          text: ' ',
          confirmButtonText: 'Đóng lại',
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            cancel(true)
          }
        })
      },
    }
  )

  if (itemIdForInfo === '0') {
    return <MaDinhDanhInfoModalForm isLoading={isLoading} madinhdanh={{Id: '0'}} />
  }

  if (!isLoading && !error && maDinhDanh) {
    return <MaDinhDanhInfoModalForm isLoading={isLoading} madinhdanh={maDinhDanh || {Id: '0'}} />
  }

  return <Loading />
}

export {MaDinhDanhInfoModalFormWrapper}
