/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContextX,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  stringifyRequestQuery,
} from '../../../../_metronic/helpers'
import {getDeviceInfoByFilter} from './_requests'
import {useQueryRequest} from './QueryRequestProvider'
import {DeviceInfo} from './_models'
import {useAuth} from '../../../modules/auth/core/Auth'

const QueryResponseContext = createResponseContextX<DeviceInfo>(initialQueryResponse)
const QueryResponseProvider: FC = ({children}) => {
  const {auth} = useAuth()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `get-DeviceInfoByFilter2-${query}`,
    () => {
      return getDeviceInfoByFilter(auth?.configs, state)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()

  if (!response) {
    return []
  }
  return response.Data.Data || []
}

const useQueryResponseTotal = () => {
  const {response} = useQueryResponse()

  if (!response) {
    return undefined
  }
  return response.Total
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseTotal,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
