import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { DongBoListWrapper } from './dongbo-list/DongBoList'
const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Trang chủ',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Quản trị hệ thống',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Cấu hình',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
]

const DongBoPage = () => {
  document.title = "Đồng bộ dữ liệu"
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='danhsach'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}></PageTitle>
              <DongBoListWrapper />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/quantrihethong/cauhinh/dongbodulieu/danhsach' />} />
    </Routes>
  )
}

export default DongBoPage
