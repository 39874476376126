import {SearchOutlined} from '@ant-design/icons'
import {Button, Input, InputRef, Space} from 'antd'
import 'antd/dist/antd.css'
import {ColumnsType, ColumnType} from 'antd/lib/table/interface'
import moment from 'moment'
import {FC, useEffect, useMemo, useRef, useState} from 'react'
import Highlighter from 'react-highlight-words'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import {TableComponent} from '../../../../../components'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTCardBody, QUERIES} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {useAuth} from '../../../../modules/auth'
import {Role} from '../../../nguoidung/nguoidung-list/core/_models'
import {UserGroupsPagination} from '../components/pagination/UserGroupsPagination'
import {useListView} from '../core/ListViewProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import {deleteCauHinhDongBo} from '../core/_requests'

type Props = {}

const DongBoTable: FC<Props> = () => {
  const {setItemIdForUpdate} = useListView()
  const manufactures = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data: any = useMemo(() => manufactures, [manufactures])
  const {state} = useQueryRequest()
  const {auth, currentUser} = useAuth()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const {refetch} = useQueryResponse()

  const EDIT_CAU_HINH_DONG_BO = currentUser?.Roles.find((item: Role) => {
    return item.Value === 66
  })
  interface DataType {
    key: string
    Id: string
    Name: string
    Info: string
    Url: string
    ApiKey: string
    CreateByUser: string
    UpdateTime: Date
    Type: number
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (record: DataType) => {
    setItemIdForUpdate(record.Id)
  }

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  type DataIndex = keyof DataType
  const [FromTime, setFromTime] = useState<string | undefined>()
  const [ToTime, setToTime] = useState<string | undefined>()
  const [isValid, setIsValid] = useState<boolean>(true)
  const {updateState} = useQueryRequest()

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => updateFilter(dataIndex, selectedKeys[0]?.toString()?.trim() || '')}
          style={{marginBottom: 8, display: 'block'}}
        />
        <div className='row d-flex'>
          <div className='col-6'>
            <Button
              type='primary'
              onClick={() => updateFilter(dataIndex, selectedKeys[0]?.toString()?.trim() || '')}
              icon={<SearchOutlined />}
              size='small'
              className='w-100'
            >
              Tìm
            </Button>
          </div>
          <div className='col-6'>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters)
                updateFilter(dataIndex, '')
              }}
              size='small'
              className='w-100'
            >
              Làm mới
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const updateFilter = (dataIndex: any, value: any) => {
    let tmp: any = {
      ...((state.filter as object) || {
        FromTime: null,
        ToTime: null,
        Name: '',
        Info: '',
        Type: null,
        CreateByUser: '',
        Url: '',
      }),
    }
    tmp[dataIndex] = value
    updateState({
      filter: tmp,
      ...initialQueryState,
    })
  }

  const getColumnSearchTime = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <div className='row d-flex'>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bold'>Từ ngày:</label>
            <Input
              ref={searchInput}
              style={{marginBottom: 8, display: 'block'}}
              type='date'
              placeholder='Từ ngày'
              name='FromTime'
              onChange={(e) => {
                if (ToTime && new Date(e.target.value) > new Date(ToTime)) setIsValid(false)
                else setIsValid(true)
                setFromTime(e.target.value)
              }}
              value={FromTime}
            />
          </div>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bold'>Đến ngày:</label>
            <Input
              ref={searchInput}
              style={{marginBottom: 8, display: 'block'}}
              type='date'
              placeholder='Đến ngày'
              name='ToTime'
              onChange={(e) => {
                if (FromTime && new Date(e.target.value) < new Date(FromTime)) setIsValid(false)
                else setIsValid(true)
                setToTime(e.target.value)
              }}
              value={ToTime}
            />
          </div>
        </div>
        <div className='row'>
          {!isValid && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  Đến ngày phải lớn hơn Từ ngày
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='row d-flex justify-content-center'>
          <div className='col-4'>
            <Button
              type='primary'
              onClick={() => {
                let tmp: any = {
                  ...((state.filter as object) || {
                    FromTime: FromTime || null,
                    ToTime: ToTime || null,
                    Name: '',
                    Info: '',
                    Type: null,
                    CreateByUser: '',
                    Url: '',
                  }),
                }
                tmp['FromTime'] = FromTime ? `${FromTime}T00:00:00Z` : null
                tmp['ToTime'] = ToTime ? `${ToTime}T23:59:59Z` : null
                updateState({
                  filter: tmp,
                  ...initialQueryState,
                })
              }}
              icon={<SearchOutlined />}
              size='small'
              className='w-100'
            >
              Tìm
            </Button>
          </div>
          <div className='col-4'>
            <Button
              onClick={() => {
                setFromTime('')
                setToTime('')
                clearFilters && handleReset(clearFilters)
                let tmp: any = {
                  ...((state.filter as object) || {
                    FromTime: FromTime || null,
                    ToTime: ToTime || null,
                    Name: '',
                    Info: '',
                    Type: null,
                    CreateByUser: '',
                    Url: '',
                  }),
                }
                tmp['FromTime'] = null
                tmp['ToTime'] = null
                updateState({
                  filter: tmp,
                  ...initialQueryState,
                })
              }}
              size='small'
              className='w-100'
            >
              Làm mới
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const Fdelete = (record: DataType) => {
    Swal.fire({
      title: 'Bạn chắc chắn muốn xóa?',
      text: record.Name,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Có',
      cancelButtonText: 'Không',
      confirmButtonColor: 'red',
      width: '500',
    }).then(async function (result) {
      if (result.value) {
        await deleteItem.mutateAsync(record.Id)
      }
    })
  }

  const deleteItem = useMutation((id: string) => deleteCauHinhDongBo(auth?.configs, id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      Swal.fire({
        icon: 'success',
        title: 'Xóa thành công',
        text: ' ',
        showConfirmButton: false,
        timer: 1500,
      })
      refetch()
    },
    onError: (error: any) => {
      Swal.fire({
        icon: 'error',
        title: error.response.data || 'Xóa thất bại',
        text: ' ',
        confirmButtonText: 'Đóng lại',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          refetch()
        }
      })
    },
  })

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1 + (state.currentPage - 1) * state.pageSize,
      align: 'center',
      width: '5%',
    },
    {
      title: 'Tên',
      dataIndex: 'Name',
      className: 'white-space col-Info-M',
      ...getColumnSearchProps('Name'),
      render: (Info) => {
        return <span title={Info}>{Info}</span>
      },
      width: '12%',
    },
    {
      title: 'Loại dữ liệu',
      dataIndex: 'Type',
      align: 'center',
      key: 'Type',
      render: (Type) => {
        return (
          <span>
            {Type === 0 ? 'MBF.TTN' : Type === 1 ? 'VTC.TTN' : 'Kết nối thiết bị trực tiếp'}
          </span>
        )
      },
      width: '12%',
    },
    {
      title: 'Thông tin',
      dataIndex: 'Info',
      className: 'white-space col-Address-M',
      ...getColumnSearchProps('Info'),
      render: (i: string) => {
        return <span title={i}>{i}</span>
      },
      width: '20%',
    },
    {
      title: 'Địa chỉ API',
      dataIndex: 'Url',
      className: 'white-space col-Address-M',
      ...getColumnSearchProps('Url'),
      render: (a: string) => {
        return <span title={a}>{a}</span>
      },
      width: '18%',
    },
    {
      title: 'Người cập nhật',
      dataIndex: 'CreateByUser',
      align: 'center',
      width: '15%',
      ...getColumnSearchProps('CreateByUser'),
    },
    {
      title: 'Thời gian cập nhật',
      dataIndex: 'UpdateTime',
      key: 'UpdateTime',
      align: 'center',
      ...getColumnSearchTime('UpdateTime'),
      render: (UpdateTime) => {
        return <p className='center m-0'>{moment(UpdateTime).format('DD/MM/YYYY HH:mm')}</p>
      },
      width: '17%',
    },
    {
      title: 'Hành động',
      key: 'Hành động',
      align: 'center',
      width: '10%',
      render: (_, record) => (
        <Space size='middle'>
          {EDIT_CAU_HINH_DONG_BO && (
            <>
              <i
                className='bi bi-pencil-fill fs-1'
                onClick={() => {
                  openEditModal(record)
                }}
                style={{
                  cursor: 'pointer',
                  padding: '5px 7px',
                  color: '#1E2EC0',
                  background: '#E0F2FF',
                  borderRadius: '5px',
                }}
              ></i>
              <i
                className='bi bi-trash text-danger fs-1'
                onClick={() => {
                  Fdelete(record)
                }}
                style={{
                  cursor: 'pointer',
                  padding: '5px 7px',
                  background: '#FFE8EF',
                  borderRadius: '5px',
                }}
              ></i>
            </>
          )}
        </Space>
      ),
    },
  ]

  return (
    <KTCardBody className='py-4'>
      <TableComponent columns={columns} dataSource={data} />
      <UserGroupsPagination />
      {isLoading && <Loading />}
    </KTCardBody>
  )
}

export {DongBoTable}
