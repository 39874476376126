/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { Role } from '../../../../app/modules/auth/core/_models'
import { Notifications } from '../../../../app/pages/thongbao/thongbao-list/core/_models'
import { get10Notification } from '../../../../app/pages/thongbao/thongbao-list/core/_requests'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

type Props = {
  isClickIconBell: boolean
  setIsClickIconBell: any
}

const HeaderNotificationsMenu: FC<Props> = ({ isClickIconBell, setIsClickIconBell }) => {
  const { auth, currentUser } = useAuth()
  const VIEW_NOTIPICATION = currentUser?.Roles.find((item: Role) => {
    return item.Value === 45
  })
  const [lst, setLst] = useState<Array<Notifications>>([])
  useEffect(() => {
    if (isClickIconBell && VIEW_NOTIPICATION) {
      get10Notification(auth?.configs).then(data => {
        setLst(data.Data || [])
        setIsClickIconBell(false)
      })
    }

  }, [isClickIconBell])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`, backgroundPosition: 'center' }}
      >
        <h3 className='text-white fw-bold px-9'>
          Thông báo
          {/* <span className='fs-8 opacity-75 ps-3'>24 reports</span> */}
        </h3>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-525px'>
            {/* <span>Chức năng này đang trong quá trình phát triển</span> */}
            {lst.length === 0 &&
              <p className='my-5 px-8 text-center'>Không có thông báo nào.</p>}
            {lst.map((n: Notifications) => {
              let dateDiff = 0
              let time = ''
              if (n.UpdateTime) {
                dateDiff = new Date().getTime() - new Date(n.UpdateTime).getTime()
                if (dateDiff < (60 * 1000))
                  time = 'Vừa xong'
                else if (dateDiff < (60 * 1000 * 60))
                  time = `${Math.floor(dateDiff / (60 * 1000))} phút trước`
                else if (dateDiff < (60 * 1000 * 60 * 24))
                  time = `${Math.floor(dateDiff / (60 * 1000 * 60))} giờ trước`
                else if (dateDiff < (60 * 1000 * 60 * 24 * 4))
                  time = `${Math.floor(dateDiff / (60 * 1000 * 60 * 24))} ngày trước`
                else
                  time = `${new Date(n.UpdateTime.toString()).getDate()}/${new Date(n.UpdateTime.toString()).getMonth() + 1}`
              }
              let href = '#'
              if (n.NotifyType === 5)
                href = '/phananh'
              else if (n.NotifyType === 6)
                href = '/ttcs/bantin'

              return (
                <div key={n.Id} className={`d-flex flex-stack py-4 px-8 border-bottom-1 ${n.ReadStatus ? '' : 'notification-new'}`}>
                  <div className='d-flex align-items-center'>
                    <div className='mb-0 me-2'>
                      <a href={href} className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                        {n.Header}
                      </a>
                      <div className='text-gray-400 fs-7'>{n.Content}</div>
                    </div>
                  </div>

                  <span className='badge badge-light fs-8'>{time}</span>
                </div>
              )
            })}
          </div>

          <div className='py-3 text-center border-top'>
            {lst.length > 0 &&
              <Link
                to='/thongbao'
                className='btn btn-color-gray-600 btn-active-color-primary'
              >
                Xem tất cả <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
              </Link>}
          </div>
        </div>

        {/* <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
        <div className='d-flex flex-column px-9'>
          <div className='pt-10 pb-0'>
            <h3 className='text-dark text-center fw-bolder'>Get Pro Access</h3>

            <div className='text-center text-gray-600 fw-bold pt-1'>
              Outlines keep you honest. They stoping you from amazing poorly about drive
            </div>

            <div className='text-center mt-5 mb-9'>
              <a
                href='#'
                className='btn btn-sm btn-primary px-6'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_upgrade_plan'
              >
                Upgrade
              </a>
            </div>
          </div>

          <div className='text-center px-4'>
            <img
              className='mw-100 mh-200px'
              alt='metronic'
              src={toAbsoluteUrl('/media/illustrations/sketchy-1/1.png')}
            />
          </div>
        </div>
      </div>

      <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
        <div className='scroll-y mh-325px my-5 px-8'>
          {defaultLogs.map((log, index) => (
            <div key={`log${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center me-2'>
                <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
                  {log.code}
                </span>

                <a href='#' className='text-gray-800 text-hover-primary fw-bold'>
                  {log.message}
                </a>

                <span className='badge badge-light fs-8'>{log.time}</span>
              </div>
            </div>
          ))}
        </div>
        <div className='py-3 text-center border-top'>
          <Link
            to='/crafted/pages/profile'
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
          </Link>
        </div>
      </div> */}
      </div>
    </div>
  )
}

export { HeaderNotificationsMenu }
