import {Empty, Table} from 'antd'
import type {ColumnsType} from 'antd/es/table'
import React from 'react'

interface TableComponentProps {
  columns: ColumnsType<any>
  dataSource: any
  onRow?: (record: any, rowIndex?: number) => {onClick: (event?: any) => void}
  rowCursor?: string
  onChange?: any
  rowSelection?: any
}

const TableComponent = (props: TableComponentProps) => {
  const {dataSource, columns, onRow, onChange} = props
  return (
    <Table
      bordered
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Không có dữ liệu' />,
      }}
      rowKey={(record) => record.Id}
      columns={columns.map((column) => ({...column, ellipsis: true}))}
      scroll={{x: 'max-content'}}
      pagination={false}
      dataSource={dataSource ?? []}
      onRow={onRow}
      onChange={onChange}
      rowSelection={props.rowSelection ? props.rowSelection : null}
    />
  )
}

export default TableComponent
