import {Pagination} from 'antd'
import 'moment/locale/vi'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'

export const CURRENT_PAGE = 1
export const DEFAULT_SIZE = 15
export const PAGE_SIZE_OPTION = [10, 20, 50, 100]

const ThietBiPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (currentPage: number, pageSize: number) => {
    if (
      !currentPage ||
      isLoading ||
      (pagination.currentPage === currentPage && pagination.pageSize === pageSize)
    ) {
      return
    }
    updateState({currentPage, pageSize: pageSize})
  }

  const showTotal = (total: number) => {
    return (
      <span>
        Tổng: <strong>{total}</strong> bản ghi
      </span>
    )
  }

  return (
    <Pagination
      locale={{
        items_per_page: '/ trang',
      }}
      className='commentBox mt-6'
      defaultPageSize={pagination.pageSize}
      showSizeChanger={true}
      current={pagination.currentPage || CURRENT_PAGE}
      onChange={updatePage}
      total={pagination.totalItems}
      showTotal={showTotal}
      pageSizeOptions={PAGE_SIZE_OPTION}
    />
  )
}

export {ThietBiPagination}
