import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ListHeader } from './components/header/ListHeader'
import { NhomQuyenTable } from './table/NhomQuyenTable'
import { KTCard } from '../../../../_metronic/helpers'
import { EditModal } from './roleGroup-edit-modal/EditModal'
import { RoleModal } from './role-modal/RoleModal'
import { RoleMoreModal } from './roleMore-modal/RoleMoreModal'

const NhomQuyenList = () => {
  const { itemIdForUpdate, selectedObject, selectedObjectTable } = useListView()
  return (
    <>
      <KTCard>
        <ListHeader />
        <NhomQuyenTable id={''} />
      </KTCard>
      {itemIdForUpdate !== '' && <EditModal />}
      {Object.keys(selectedObject).length !== 0 && <RoleModal />}
      {Object.keys(selectedObjectTable).length !== 0 && <RoleMoreModal />}
    </>
  )
}

const NhomQuyenListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <NhomQuyenList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { NhomQuyenListWrapper }
