import clsx from 'clsx'
import React, {FC, useEffect} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {useAuth} from '../../../../../app/modules/auth'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'
import {Typography} from 'antd'

const DefaultTitle: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs, setPageTitle} = usePageData()
  const {config, classes} = useLayout()
  const {logout} = useAuth()

  const {pathname} = useLocation()

  useEffect(() => {
    if (pageBreadcrumbs && pageBreadcrumbs.length > 0) {
      setPageTitle('')
    }
  }, [pageBreadcrumbs, setPageTitle])

  return (
    // <>
    //   <div
    //     id='kt_page_title'
    //     data-kt-swapper='true'
    //     data-kt-swapper-mode='prepend'
    //     data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
    //     className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    //   >
    //     {/* begin::Title */}
    //     {pageTitle && (
    //       <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
    //         {pageTitle}
    //         {pageDescription && config.pageTitle && config.pageTitle.description && (
    //           <>
    //             <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
    //             <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
    //           </>
    //         )}
    //       </h1>
    //     )}
    //     {/* end::Title */}

    //     {pageBreadcrumbs &&
    //       pageBreadcrumbs.length > 0 &&
    //       config.pageTitle &&
    //       config.pageTitle.breadCrumbs && (
    //         <>
    //           {/* {config.pageTitle.direction === 'row' && (
    //             <span className='h-20px border-gray-200 border-start mx-4'></span>
    //           )} */}
    //           <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-6 my-1'>
    //             {Array.from(pageBreadcrumbs).map((item, index) => (
    //               <li
    //                 className={clsx('breadcrumb-item ', {
    //                   'text-dark': !item.isSeparator && item.isActive,
    //                   'text-muted': !item.isSeparator && !item.isActive,
    //                 })}
    //                 key={`${item.path}${index}`}
    //               >
    //                 {!item.isSeparator ? (
    //                   <Link className='text-dark text-hover-primary' to={item.path}>
    //                     {item.title}
    //                   </Link>
    //                 ) : (
    //                   <span className='bullet bg-gray-200 w-5px h-2px'></span>
    //                 )}
    //               </li>
    //             ))}
    //             <li className='breadcrumb-item text-dark'>{pageTitle}</li>
    //           </ul>
    //         </>
    //       )}
    //     {/* <button type='button' onClick={logout} className='btn-logout' title='Đăng xuất'>
    //       <i className='bi bi-box-arrow-right' style={{ fontSize: '23px' }}></i>
    //     </button> */}
    //   </div>
    // </>
    <>
      {pageTitle && pathname !== '/tongquan' && (
        <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
          {pageTitle}
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )}
        </h1>
      )}
      {/* end::Title */}

      {pageTitle && pathname === '/tongquan' && pageBreadcrumbs && pageBreadcrumbs.length === 0 && (
        <div>
          <Typography.Title level={1} style={{margin: 0}}>
            {pageTitle}
          </Typography.Title>
        </div>
      )}

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            {/* {config.pageTitle.direction === 'row' && (
            <span className='h-20px border-gray-200 border-start mx-4'></span>
          )} */}
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-6 my-1'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item ', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                    <Link className='text-dark text-hover-primary' to={item.path}>
                      {item.title}
                    </Link>
                  ) : (
                    <span className='w-5px h-2px'>/</span>
                  )}
                </li>
              ))}
              <li className='breadcrumb-item text-dark'>{pageTitle}</li>
            </ul>
          </>
        )}
    </>
  )
}

export {DefaultTitle}
