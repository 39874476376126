/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, FC } from 'react'
import { useMutation } from 'react-query'
import { initialQueryState, KTSVG, useDebounce } from '../../../../../../_metronic/helpers'
import { Loading } from '../../../../../../_metronic/layout/components/loading/Loading'
import { useAuth } from '../../../../../modules/auth/core/Auth'
import { useQueryRequest } from '../../core/QueryRequestProviderNKBDT'
import { ExportListDeviceLog } from '../../core/_requests'
import { DownloadOutlined } from '@ant-design/icons'

type Props = {
  changeTab: any
}
const ListSearchComponent: FC<Props> = ({ changeTab }) => {
  const { auth, addressD, setAddressD } = useAuth()
  const { state, updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>(addressD || '')
  const [isLoading, setIsLoading] = useState(false)

  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({
          search: debouncedSearchTerm.trim(),
          search2: debouncedSearchTerm.trim(),
          ...initialQueryState,
        })
      }
      return () => {
        setAddressD('')
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  const Export = () => {
    setIsLoading(true)
    exportList.mutateAsync()
  }

  const exportList = useMutation(() => ExportListDeviceLog(auth?.configs, state), {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Danhsachnhatkybangdientu.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setIsLoading(false)
    },
  })

  return (
    <>
      {/* <div className='d-flex align-items-center me-3'>
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid ps-14'
          placeholder='Tìm kiếm IMEI, Nhà cung cấp, Thiết bị...'
          style={{ width: '406px' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
      </div> */}
      <button type='button' className='btn btn-primary me-3' onClick={Export}>
        <DownloadOutlined />
        Xuất dữ liệu
      </button>
      <button type='button' className='btn btn-light-primary' onClick={() => changeTab(2)}>
        Quay lại
        <KTSVG
          path={'/media/icons/duotune/arrows/arr080.svg'}
          className={'svg-icon-1 rotate-180 btn-back'}
        />
      </button>
      {isLoading && <Loading />}
    </>
  )
}

export { ListSearchComponent }
