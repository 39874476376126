import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {defaultConfig} from '../../../../configuration/config'
import {getForgotQuestion} from '../../../../enum/forgot-question.enum'
import {useQueryResponse} from '../../../pages/nguoidung/nguoidung-list/core/QueryResponseProvider'
import {User} from '../../../pages/nguoidung/nguoidung-list/core/_models'
import {ForgotPassworded} from '../../../pages/nguoidung/nguoidung-list/core/_requests'
import {useAuth} from '../core/Auth'

const forgotPasswordSchema = Yup.object().shape({
  Email: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
  Answer1: Yup.string()
    .trim()
    .max(250, 'Tối đa 250 ký tự')
    .required('Câu trả lời 1 không được để trống'),
  Answer2: Yup.string()
    .trim()
    .max(250, 'Tối đa 250 ký tự')
    .required('Câu trả lời 2 không được để trống'),
})

export function ForgotPassword(user: User, isUserLoading: boolean) {
  const {refetch} = useQueryResponse()
  const {auth, configs} = useAuth()
  const listQuestion = getForgotQuestion()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const userForEdit = {
    Email: '',
    Answer1: '',
    Answer2: '',
    Questions1: '',
    Questions2: '',
  }

  const [userEdit] = useState<User>({
    ...user,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      userEdit.Answer1 = values.Answer1
      userEdit.Questions1 = values.Questions1 || 'Thành phố bạn sinh ra tên là gì?'
      userEdit.Answer2 = values.Answer2
      userEdit.Questions2 = values.Questions2 || 'Thành phố bạn sinh ra tên là gì?'
      userEdit.Email = values.Email

      try {
        await ForgotPassworded(
          configs || defaultConfig,
          userEdit.Questions1,
          userEdit.Answer1,
          userEdit.Questions2,
          userEdit.Answer2,
          userEdit.Email || ''
        )
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Cập nhật mật khẩu thành công. Mật khẩu mới đã được gửi tới email của bạn.',
              text: ' ',
              confirmButtonText: 'Về trang đăng nhập',
              confirmButtonColor: '#009EF7',
            }).then(() => {
              document.getElementById('kt_login_password_reset_form_cancel_button')?.click()
            })
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title: error.response.data || 'Cập nhật thông tin thất bại',
              text: ' ',
              confirmButtonText: 'Đóng lại',
              confirmButtonColor: '#009EF7',
            })
          })
      } catch (ex) {
        console.error(ex)
      } finally {
      }
    },
  })

  return (
    <>
      <div className='w-lg-650px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-8'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Quên mật khẩu</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>
              Nhập vào email và câu hỏi bảo mật của bạn để thiết lập lại mật khẩu
            </div>
            {/* end::Link */}
          </div>
          {/* begin::Form group */}
          <div className='row'>
            <label className='required form-label fw-bolder text-gray-900 fs-6'>Email</label>
            <input
              type='email'
              placeholder='Email'
              autoComplete='off'
              {...formik.getFieldProps('Email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.Email && formik.errors.Email},
                {
                  'is-valid': formik.touched.Email && !formik.errors.Email,
                }
              )}
            />
            {formik.touched.Email && formik.errors.Email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.Email}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          <div className='row mt-5'>
            <div className='col-6'>
              {/* begin::Input */}
              <label className='required fw-bold fs-6 mb-2'>Câu hỏi 1</label>

              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('Questions1')}
              >
                {listQuestion.map((item) => (
                  <option value={item?.value}>{item?.label}</option>
                ))}
              </select>
            </div>

            <div className='col-6'>
              {/* begin::Input */}
              <label className='required fw-bold fs-6 mb-2'>Trả lời 1</label>
              <input
                placeholder='Câu trả lời 1'
                {...formik.getFieldProps('Answer1')}
                name='Answer1'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0', {
                  'is-valid': formik.touched.Answer1 && !formik.errors.Answer1,
                })}
                autoComplete='off'
              />
              {formik.touched.Answer1 && formik.errors.Answer1 && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Answer1}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mt-5 fv-row mb-10'>
            <div className='col-6'>
              {/* begin::Input */}
              <label className='required fw-bold fs-6 mb-2'>Câu hỏi 2</label>

              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('Questions2')}
              >
                {listQuestion.map((item) => (
                  <option value={item?.value}>{item?.label}</option>
                ))}
              </select>
            </div>

            <div className='col-6'>
              {/* begin::Input */}
              <label className='required fw-bold fs-6 mb-2'>Trả lời 2</label>
              <input
                placeholder='Câu trả lời 2'
                {...formik.getFieldProps('Answer2')}
                name='Answer2'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0', {
                  'is-valid': formik.touched.Answer2 && !formik.errors.Answer2,
                })}
                autoComplete='off'
              />
              {formik.touched.Answer2 && formik.errors.Answer2 && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Answer2}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder me-4'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Quay lại
              </button>
            </Link>{' '}
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder'
            >
              <span className='indicator-label'>Gửi</span>
              {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Vui lòng đợi...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </>
  )
}
