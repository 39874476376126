import { FC } from 'react'
import { Role } from '../../../../modules/auth'

type Props = {
  roles: Array<Role> // Ds quyền của user
}
const RoleMoreModalForm: FC<Props> = ({ roles }) => {

  return (
    <>
      <div id='modalListUserContent' className='col-12'>
        {roles.map((r: Role) => {
          return <span title={r.Name} className='span-user'>{r.Name}</span>
        })}
      </div>
    </>
  )
}

export { RoleMoreModalForm }
