import axios, {AxiosError, AxiosResponse} from 'axios'
import {DynamicConfig, dynamicConfigUrl} from '../../../../configuration/config'
import {AuthModel, City} from './_models'
import {refetchTokenService} from './_requests'
import Swal from 'sweetalert2'
import {swap} from 'formik'
import {log} from 'console'

interface ErrorResponse {
  type: string
  status: string
  message: string
}
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const HOT_LINE = process.env.REACT_APP_HOT_LINE ?? '024.6666.8989'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (res: any) => {
      return res
    },
    async (err: AxiosError) => {
      const originalConfig = (err?.config as any) ?? {}

      if (!originalConfig?.url.includes('/users/login') && err.response) {
        // Check if the response status is 401 (Unauthorized) and the request has not been retried
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true
          const item = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)

          const auth = item && JSON.parse(item)

          // If user does not exist in memory, navigate to the login page
          if (!auth.RefreshToken || !auth.AccessToken) {
            localStorage.clear()
            window.location.href = '/auth/login'
            return
          }
          // Refresh the token using the AuthService.refreshToken() method
          refetchTokenService(auth.configs, {
            CaseLogin: auth.CaseLogin,
            Info: '',
            RefreshToken: auth.RefreshToken,
          })
            .then((data) => {
              const newAuth = {
                ...auth,
                api_token: data.data.AccessToken,
                RefreshToken: data.data.RefreshToken,
                AccessToken: data.data.AccessToken,
              }
              setAuth(newAuth)
              // Retry the request with the updated configuration
              return axios(originalConfig)
            })
            .catch((err: any) => {
              localStorage.clear()
              window.location.href = '/auth/login'
              return
            })
        }
      }

      if (err.response?.status === 500 || err.response?.status === 502) {
        getConfigs()
          .then((data: any) => {
            Swal.fire({
              icon: 'warning',
              title:
                'Hệ thống đang bảo trì. Kính xin quý khách vui lòng đăng nhập lại sau ít phút!',
              text:
                'Thông tin chi tiết vui lòng liên hệ với số hotline: ' + data.hotline ||
                process.env.REACT_APP_HOT_LINE ||
                HOT_LINE,
              confirmButtonText: 'Đóng lại',
            }).then(() => {
              if (localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)) {
                localStorage.clear()
                window.location.href = '/auth/login'
              }
            })
          })
          .finally(() => {})
      }

      return Promise.reject<ErrorResponse>(err)
    }
  )
}

const setCity = (city: City) => {
  if (!localStorage.getItem('CITY_STORAGE')) {
    return
  }

  try {
    const lsValue = JSON.stringify(city)
    localStorage.setItem('CITY_STORAGE', lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const getCity = (): City | undefined => {
  if (!localStorage.getItem('CITY_STORAGE')) {
    return
  }

  const lsValue: string | null = localStorage.getItem('CITY_STORAGE')
  if (!lsValue) {
    return
  }

  try {
    const cityInfo: City = JSON.parse(lsValue) as City
    if (cityInfo) {
      // You can easily check auth_token expiration also
      return cityInfo
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const getConfigs = (): Promise<DynamicConfig | undefined> => {
  return axios.get(dynamicConfigUrl).then((d: AxiosResponse<DynamicConfig>) => d.data)
}

export {AUTH_LOCAL_STORAGE_KEY, getAuth, getCity, getConfigs, removeAuth, setAuth, setCity}
