import {KTCard} from '../../../../_metronic/helpers'
import {MaDinhDanhListHeader} from './components/header/MaDinhDanhListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {MaDinhDanhEditModal} from './maDinhDanh-edit-modal/maDinhDanhEditModal'
import {MaDinhDanhInfoModal} from './maDinhDanh-info-modal/maDinhDanhInfoModal'
import {MaDinhDanhTable} from './table/MaDinhDanhTable'

const MaDinhDanhList = () => {
  const {itemIdForUpdate, itemIdForInfo} = useListView()
  return (
    <>
      <KTCard>
        <MaDinhDanhListHeader />
        <MaDinhDanhTable />
      </KTCard>
      {itemIdForInfo !== '' && <MaDinhDanhInfoModal />}
      {itemIdForUpdate !== '' && <MaDinhDanhEditModal />}
    </>
  )
}

const MaDinhDanhListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <MaDinhDanhList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MaDinhDanhListWrapper}
