import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ThietBiListWrapper } from './thietbi-list/ThietBiList'
const ThietBiBreadcrumbs: Array<PageLink> = [
  {
    title: 'Trang chủ',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Quản lý hoạt động TTCS',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Danh sách thiết bị',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
]

const ThietBiPage = () => {
  document.title = "Danh sách thiết bị"
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='danhsach'
          element={
            <>
              <PageTitle breadcrumbs={ThietBiBreadcrumbs}></PageTitle>
              <ThietBiListWrapper />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/ttcs/thietbi/danhsach' />} />
    </Routes>
  )
}

export default ThietBiPage
