/* eslint-disable jsx-a11y/anchor-is-valid */
//import clsx from 'clsx'
import { Pagination } from 'antd'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponseLoading, useQueryResponsePagination } from '../../core/QueryResponseProvider'
//import styled from 'styled-components'
export const CURRENT_PAGE = 1
export const DEFAULT_SIZE = 15
export const PAGE_SIZE_OPTION = [10, 20, 50, 100]

// const MyPaginate = styled(ReactPaginate).attrs({
//   // You can redifine classes here, if you want.
//   activeClassName: 'active', // default to "disabled"
// })`
//   margin-bottom: 2rem;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   list-style-type: none;
//   padding: 0 5rem;

//   li a {
//     border-radius: 7px;
//     padding: 0.1rem 1rem;
//     border: gray 1px solid;
//     cursor: pointer;
//   }
//   li.previous a,
//   li.next a,
//   li.break a {
//     border-color: transparent;
//   }
//   li.active a {
//     background-color: #0366d6;
//     border-color: transparent;
//     color: white;
//     min-width: 32px;
//   }
//   li.disabled a {
//     color: grey;
//   }
//   li.disable,
//   li.disabled a {
//     cursor: default;
//   }
// `;

const UserGroupsPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { updateState } = useQueryRequest()
  const updatePage = (currentPage: number, pageSize: number) => {
    if (
      !currentPage ||
      isLoading ||
      (pagination.currentPage === currentPage && pagination.pageSize === pageSize)
    ) {
      return
    }
    updateState({currentPage, pageSize: pageSize})
  }

  const showTotal = (total: number) => {
    return (
      <span>
        Tổng: <strong>{total}</strong> bản ghi
      </span>
    )
  }

  return (
    <Pagination
      locale={{
        items_per_page: '/ trang',
      }}
      className='commentBox mt-6'
      defaultPageSize={pagination.pageSize}
      showSizeChanger={true}
      current={pagination.currentPage || CURRENT_PAGE}
      onChange={updatePage}
      total={pagination.totalItems}
      showTotal={showTotal}
      pageSizeOptions={PAGE_SIZE_OPTION}
    />
  )
}

export { UserGroupsPagination }

