import { DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { Loading } from '../../../../../../_metronic/layout/components/loading/Loading'
import { BtnRefetch } from '../../../../../../components'
import { useAuth } from '../../../../../modules/auth/core/Auth'
import { Role } from '../../../../../modules/auth/core/_models'
import { useListView } from '../../core/ListViewProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse, useQueryResponseData } from '../../core/QueryResponseProvider'
import { ExportRoleGroup } from '../../core/_requests'
import { UsersListSearchComponent } from './ListSearchComponent'

const ListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const data = useQueryResponseData()
  const {refetch} = useQueryResponse()
  const [isLoading, setIsLoading] = useState(false)
  const {auth, currentUser} = useAuth()
  const EDIT_ROLE_GROUP = currentUser?.Roles.find((item: Role) => {
    return item.Value === 57
  })

  const {state} = useQueryRequest()

  const Export = () => {
    setIsLoading(true)
    exportUserGroup.mutateAsync()
  }

  const exportUserGroup = useMutation(() => ExportRoleGroup(auth?.configs, state), {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `danhsachnhomquyen.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setIsLoading(false)
    },
    onError: (error) => {},
  })

  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        <UsersListSearchComponent />
        {/* <UsersListFilter /> */}

        {/* begin::Export */}
        <BtnRefetch refetch={refetch} />

        <button
          type='button'
          className='btn btn-primary me-3'
          disabled={data.length == 0}
          onClick={Export}
        >
          <DownloadOutlined />
          Xuất dữ liệu
        </button>
        {/* end::Export */}

        {/* begin::Add user */}
        {EDIT_ROLE_GROUP && (
          <button
            type='button'
            className='btn btn-primary bt-button'
            onClick={() => setItemIdForUpdate('0')}
          >
            <PlusOutlined />
            Thêm mới
          </button>
        )}
        {/* end::Add user */}
      </div>
      {isLoading && <Loading />}
    </>
  )
}

export { ListToolbar }

