import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {getDistrictByCityId, getWardByDistrictId} from '../../../diachi/diachi-list/core/_requests'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
//import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {City, District, maDinhDanh, Ward} from '../core/_models'
import {getProvinces} from '../core/_requests'

type Props = {
  isLoading: boolean
  madinhdanh: maDinhDanh
}

const editMaDinhDanhSchema = Yup.object().shape({
  Name: Yup.string()
    .trim()
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự')
    .required('Tên không được để trống'),
  NguonID: Yup.string()
    .required('mã không được để trống')
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự'),
})

const MaDinhDanhInfoModalForm: FC<Props> = ({madinhdanh, isLoading}) => {
  const {auth} = useAuth()
  const {setItemIdForInfo, citySelected, setWardSelected} = useListView()
  const {refetch} = useQueryResponse()
  const [district, setDistrict] = useState<string>(madinhdanh.Address?.DistrictName || '')
  const [ward, setWard] = useState(madinhdanh.Address?.WardName || '')
  const [lstCity, setLstCity] = useState<Array<City>>([])
  const [lstDistrict, setLstDistrict] = useState<Array<District>>([])
  const [listWard, setLstWard] = useState(new Array<Ward>())
  const [maDinhDanhForEdit] = useState<maDinhDanh>({
    ...madinhdanh,
  })

  useEffect(() => {
    getProvinces(auth?.configs).then((data) => {
      if (data.length > 0) {
        setLstCity(data)
        let cId = data[0].CityId

        getDistrictByCityId(auth?.configs, data[0].CityId || '').then((dataD) => {
          if (dataD.length > 0) {
            setLstDistrict(dataD)
            let dId = dataD[0].DistrictId
            // if (deviceInfo.Address?.WardName) {
            //   let arr = data.filter(function (g: District) {
            //     return g.DistrictName === district
            //   })
            //   if (arr.length > 0) dId = arr[0].DistrictId
            // }
            getWardByDistrictId(auth?.configs, cId || '', dId || '').then((dataW) => {
              setLstWard(dataW)
            })
          }
        })
      }
    })
  }, [])

  const madinhDanhForEdit = {
    Name: madinhdanh.Name,
    NguonID: madinhdanh.NguonID,
    Address: madinhdanh.Address,
    Info: madinhdanh.Info,
    CityName: madinhdanh.Address?.CityName || citySelected?.CityName,
    DistrictName: madinhdanh.Address?.DistrictName || lstDistrict[0]?.DistrictName,
    WardName: madinhdanh.Address?.WardName || '',
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
      setWardSelected({})
    }
    setItemIdForInfo('')
  }

  const formik = useFormik({
    initialValues: madinhDanhForEdit,
    validationSchema: editMaDinhDanhSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      maDinhDanhForEdit.Name = values.Name
      maDinhDanhForEdit.NguonID = values.NguonID
      maDinhDanhForEdit.Info = values.Info
      maDinhDanhForEdit.Address = {
        CityName: values.CityName,
        DistrictName: district,
        WardName: ward,
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Tên</label>
              <input
                placeholder='Tên'
                {...formik.getFieldProps('Name')}
                type='text'
                name='Name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Name && formik.errors.Name},
                  {
                    'is-valid': formik.touched.Name && !formik.errors.Name,
                  }
                )}
                autoComplete='off'
                disabled={true}
              />
              {formik.touched.Name && formik.errors.Name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Name}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Label */}
            </div>
          </div>
          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Mã</label>
              <input
                placeholder='Mã'
                {...formik.getFieldProps('NguonID')}
                type='text'
                name='NguonID'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.NguonID && formik.errors.NguonID},
                  {
                    'is-valid': formik.touched.NguonID && !formik.errors.NguonID,
                  }
                )}
                autoComplete='off'
                disabled={true}
              />
              {formik.touched.NguonID && formik.errors.NguonID && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.NguonID}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Label */}
            </div>
          </div>

          <div className='row mt-5'>
            <div className='col-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Địa chỉ</label>
              <input
                placeholder='Địa chỉ'
                {...formik.getFieldProps('Info')}
                type='text'
                name='Info'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Info && formik.errors.Info},
                  {
                    'is-valid': formik.touched.Info && !formik.errors.Info,
                  }
                )}
                autoComplete='off'
                disabled={true}
              />
              {/* end::Label */}
            </div>
            <div className='col-6'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Tỉnh</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                disabled
                {...formik.getFieldProps('CityName')}
              >
                {lstCity?.map((c: City) => (
                  <option key={c.CityId} value={c.CityName}>
                    {c.CityName}
                  </option>
                ))}
              </select>
              {/* end::Label */}
            </div>
          </div>

          <div className='row mt-5'>
            <div className='col-6'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Huyện</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                disabled
                {...formik.getFieldProps('DistrictName')}
                // value={district}
                // onChange={(event) => getLstWard(event.target.options.selectedIndex)}
              >
                {lstDistrict?.map((district: District) => (
                  <option key={district.DistrictId} value={district.DistrictName}>
                    {district.DistrictName}
                  </option>
                ))}
              </select>
              {/* end::Input */}
              {formik.touched.DistrictName && formik.errors.DistrictName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.DistrictName}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='col-6'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Xã</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                disabled
                {...formik.getFieldProps('WardName')}
                value={ward}
                onChange={(event) => setWard(event.target.value)}
              >
                {listWard?.map((ward: Ward, i) => (
                  <option key={i} value={ward.WardName}>
                    {ward.WardName}
                  </option>
                ))}
              </select>
              {/* end::Label */}
            </div>
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-outline-primary me-3 cancel'
            data-kt-users-modal-action='cancel'
          >
            Đóng
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export {MaDinhDanhInfoModalForm}
