import React from 'react'

const BtnRefetch = ({refetch}: {refetch: () => void}) => {
  return (
    <button
      type='button'
      className='btn btn-light-primary me-3'
      title='Tải lại dữ liệu'
      onClick={() => refetch()}
    >
      <i className='bi bi-arrow-clockwise' style={{paddingRight: 0}}></i>
    </button>
  )
}

export default BtnRefetch
