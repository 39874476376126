import axios, {AxiosResponse} from 'axios'
import {DynamicConfig} from '../../../../../configuration/config'
import {QueryState, Response} from '../../../../../_metronic/helpers'
import {FileResponse} from '../../../MAP_New2/core/_models'
import {NewsPapersQueryResponse, PingState, StreamingInfo, Streamings} from './_models'

const streaming = '/streaming'
const meetingrtc = '/meeting-rtc'
const uploadFile = '/uploadFile'

const getNewsPapers = (
  configs: DynamicConfig,
  state: QueryState
): Promise<NewsPapersQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${streaming}/get-dsBanTinGiay`, {
      headers: state,
    })
    .then((d: AxiosResponse<NewsPapersQueryResponse>) => d.data)
}

const getNewsPapersById = (configs: DynamicConfig, id: string): Promise<Streamings | undefined> => {
  return axios
    .post(`${configs.apiUrl}${streaming}/get-banTinGiayById`, {Id: id})
    .then((d: AxiosResponse<Streamings>) => d.data)
}

const getStreamingByFilter = (
  configs: DynamicConfig,
  state: QueryState
): Promise<NewsPapersQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${streaming}/get-streamingByFilter`,
      state.filter || {
        FromTime: null,
        ToTime: null,
        Name: '',
        Info: '',
        CreateByUser: '',
      },
      {
        headers: {
          currentPage: state.currentPage,
          pageSize: state.pageSize,
        },
      }
    )
    .then((d: AxiosResponse<NewsPapersQueryResponse>) => d.data)
}

const createStreamingGroup = (
  configs: DynamicConfig,
  streamings: StreamingInfo
): Promise<StreamingInfo | undefined> => {
  return axios
    .post(`${configs.apiUrl}${streaming}/create-streamingGroup`, streamings)
    .then((response: AxiosResponse<Response<StreamingInfo>>) => response.data)
    .then((response: Response<StreamingInfo>) => response.data)
}

const updateNewsPapers = (
  configs: DynamicConfig,
  newspapers: Streamings
): Promise<Streamings | undefined> => {
  return axios
    .post(`${configs.apiUrl}${streaming}/update-banTinGiay`, newspapers)
    .then((response: AxiosResponse<Response<Streamings>>) => response.data)
    .then((response: Response<Streamings>) => response.data)
}

const uploadFileTT = (configs: DynamicConfig, file: Blob): Promise<FileResponse> => {
  const formData = new FormData()
  let tmps = file.type.split('/')
  if (tmps[tmps.length - 1] === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    tmps[tmps.length - 1] = 'xlsx'
  }
  formData.append('FileExtension', tmps[tmps.length - 1])
  formData.append('UploadedFile', file)
  return axios
    .post(`${configs.apiUrl}${uploadFile}/uploadFile`, formData)
    .then((response: AxiosResponse<FileResponse>) => response.data)
}

const uploadFileBlob = (configs: DynamicConfig, formData: FormData): Promise<FileResponse> => {
  return axios
    .post(`${configs.apiUrl}${uploadFile}/uploadFile`, formData)
    .then((response: AxiosResponse<FileResponse>) => response.data)
}

const recordMeeting = (configs: DynamicConfig, formData: FormData) => {
  return axios.post(`${configs.apiUrl}/meeting-recording`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

const liveMeeting = (configs: DynamicConfig, formData: FormData) => {
  return axios.post(`${configs.apiUrl}/meeting-recording/live-stream`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

const deleteStreaming = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios.put(`${configs.apiUrl}${streaming}/delete-streaming`, {Id: Id}).then(() => {})
}

const stopStreaming = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${streaming}/stop-streaming`, {
      CallId: Id,
    })
    .then(() => {})
}

const pingStreamState = (configs: DynamicConfig, payload: PingState): Promise<void> => {
  return axios.post(`${configs.apiUrl}${streaming}/ping-streamingState`, payload).then(() => {})
}

const leaveMeetingCall = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios.post(`${configs.apiUrl}${streaming}/leave-streaming`, {CallId: Id}).then(() => {})
}

const joinStreaming = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios.post(`${configs.apiUrl}${streaming}/join-streaming`, {CallId: Id}).then(() => {})
}

const callSOS = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${streaming}/create-streamingEmergency`, {CallId: Id})
    .then(() => {})
}

const callEmergency = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${meetingrtc}/create-emergency`, {CallId: Id})
    .then(() => {})
}

const ExportStreaming = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(
      `${configs.apiUrl}${streaming}/export-streaming`,
      state.filter || {
        FromTime: null,
        ToTime: null,
        Name: '',
        Info: '',
        CreateByUser: '',
      },
      {responseType: 'blob'}
    )
    .then((d: AxiosResponse<Blob>) => d.data)
}

export {
  getNewsPapersById,
  getNewsPapers,
  deleteStreaming,
  ExportStreaming,
  createStreamingGroup,
  updateNewsPapers,
  getStreamingByFilter,
  uploadFileTT,
  stopStreaming,
  joinStreaming,
  leaveMeetingCall,
  pingStreamState,
  callSOS,
  uploadFileBlob,
  recordMeeting,
  liveMeeting,
  callEmergency
}
