//import React, {useState} from 'react'
import axios, { AxiosResponse } from 'axios'
import { DynamicConfig } from '../../../../../configuration/config'
//import { Query } from 'react-query'
import { QueryState, Response } from '../../../../../_metronic/helpers'
import { City, maDinhDanh, maDinhDanhsQueryResponse } from './_models'

const MaDinhDanhs = '/MaDinhDanh'
const location = '/location'

const getMaDinhDanhs = (configs: DynamicConfig): Promise<maDinhDanhsQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${MaDinhDanhs}/get-maDinhDanhs`, {
      headers: {
        currentPage: 1,
        pageSize: 9999
      }
    })
    .then((d: AxiosResponse<maDinhDanhsQueryResponse>) => d.data)
}


const getAllMaDinhDanh = (configs: DynamicConfig, state: QueryState): Promise<maDinhDanhsQueryResponse> => {
  return axios
    .post(`${configs.apiUrl}${MaDinhDanhs}/get-maDinhDanhFilter`, state.filter || {
      FromTime: null,
      ToTime: null,
      Name: "",
      Address: "",
      CreateByUser: "",
      NguonID: ""
    }, {
      headers: {
        currentPage: state.currentPage,
        pageSize: state.pageSize
      }
    })
    .then((d: AxiosResponse<maDinhDanhsQueryResponse>) => d.data)
}

const getMaDinhDanhById = (configs: DynamicConfig, id: string): Promise<maDinhDanh | undefined> => {
  return axios
    .post(`${configs.apiUrl}${MaDinhDanhs}/get-maDinhDanhById`, {
      Id: id
    })
    .then((d: AxiosResponse<maDinhDanh>) => d.data)
}

const createMaDinhDanh = (configs: DynamicConfig, maDinhDanhes: maDinhDanh): Promise<maDinhDanh | undefined> => {
  return axios
    .post(`${configs.apiUrl}${MaDinhDanhs}/create-maDinhDanh`, {
      Name: maDinhDanhes.Name,
      NguonID: maDinhDanhes.NguonID,
      Info: maDinhDanhes.Info,
      Address: maDinhDanhes.Address,
    })
    .then((response: AxiosResponse<Response<maDinhDanh>>) => response.data)
    .then((response: Response<maDinhDanh>) => response.data)
}

const getProvinces = (configs: DynamicConfig): Promise<Array<City>> => {
  return axios
    .get(`${configs.apiUrl}${location}/get-listCityByUser`)
    .then((response: AxiosResponse<Array<City>>) => response.data)
}

const updateMaDinhDanh = (configs: DynamicConfig, maDinhDanhes: maDinhDanh): Promise<maDinhDanh | undefined> => {
  return axios
    .post(`${configs.apiUrl}${MaDinhDanhs}/update-maDinhDanh`, {
      Id: maDinhDanhes.Id,
      Name: maDinhDanhes.Name,
      NguonID: maDinhDanhes.NguonID,
      Info: maDinhDanhes.Info,
      Address: maDinhDanhes.Address,
    })
    .then((response: AxiosResponse<Response<maDinhDanh>>) => response.data)
    .then((response: Response<maDinhDanh>) => response.data)
}

const ExportMaDinhDanh = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(`${configs.apiUrl}${MaDinhDanhs}/export-maDinhDanh`, state.filter || {
      FromTime: null,
      ToTime: null,
      Name: "",
      Address: "",
      CreateByUser: "",
      NguonID: "",
    }, { responseType: "blob" })
    .then((d: AxiosResponse<Blob>) => d.data)
}

const deleteMaDinhDanh = (configs: DynamicConfig, Id: string): Promise<void> => {
  return axios.put(`${configs.apiUrl}${MaDinhDanhs}/delete-maDinhDanh`, { "Id": Id }).then(() => { })

}


export { getMaDinhDanhs, getAllMaDinhDanh, getMaDinhDanhById, createMaDinhDanh, updateMaDinhDanh, ExportMaDinhDanh, deleteMaDinhDanh, getProvinces }
