import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { useAuth } from '../../../../modules/auth/core/Auth'
import { Loading } from '../../../../../_metronic/layout/components/loading/Loading'
import { useListViewCamera} from '../core/ListViewProviderCamera'
import { useQueryResponse } from '../core/QueryResponseProviderCamera'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DeviceDisplay } from '../core/_models'
import { updateDeviceDisplayActive } from '../core/_requests'
import dayjs from 'dayjs'

type Props = {
  isLoading: boolean
  deviceInfo: DeviceDisplay
}

const editDeviceInfoSchema = Yup.object().shape({

})

const ModalForm: FC<Props> = ({ deviceInfo, isLoading }) => {
  const { setItemIdForSettingLed } = useListViewCamera()
  const { refetch } = useQueryResponse()
  const { auth } = useAuth()
  const [active, setActive] = useState<boolean>(deviceInfo.IsActive || false)
  const [beginTime, setBeginTime] = useState<any>(deviceInfo.DisplayTimeStart)
  const [endTime, setEndTime] = useState<any>(deviceInfo.DisplayTimeStop)

  useEffect(() => {
    let start = new Date(beginTime)
    start?.setHours(start.getHours() - 7)
    setBeginTime(dayjs(start))
    let end = new Date(endTime)
    end?.setHours(end.getHours() - 7)
    setEndTime(dayjs(end))
  }, [])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh)
      refetch()
    setItemIdForSettingLed('')
  }

  const converDate = (value: any) => {
    let now = new Date()
    let day = now.toISOString().substring(0, 11)
    let dValue = new Date(value)
    let h = dValue.getHours()
    let m = dValue.getMinutes()
    let s = dValue.getSeconds()
    // return `${day}${h > 9 ? h : `0${h}`}:${m > 9 ? m : `0${m}`}:00`
    now.setHours(h + 7)
    now.setMinutes(m)
    now.setSeconds(s)
    return now.toISOString()
  }

  const formik = useFormik({
    initialValues: deviceInfo,
    validationSchema: editDeviceInfoSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      values.IsActive = active
      try {
        await updateDeviceDisplayActive(auth?.configs, values, converDate(beginTime), converDate(endTime))
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Cập nhật thông tin thành công',
              text: ' ',
              showConfirmButton: false,
              timer: 1500,
            })
            setSubmitting(true)
            cancel(true)
          })
          .catch((error) =>
            Swal.fire({
              icon: 'error',
              title: typeof (error.response.data) != 'object' ? error.response.data : 'Cập nhật thông tin thất bại',
              text: ' ',
              confirmButtonText: 'Đóng lại',
            })
          )
      } catch (ex) {
        console.error(ex)
      } finally {

      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row mt-5' style={{ alignItems: 'center' }}>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-4 mb-2'>Thời gian bật</label>
              {/* end::Label */}
            </div>
            <div className='col-8'>
              {/* begin::Input */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker views={['hours', 'minutes', 'seconds']} value={beginTime}
                  ampm={false} timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  className='form-control form-control-solid'
                  onChange={(newValue) => setBeginTime(newValue)} />
              </LocalizationProvider>
              {/* end::Label */}
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='row mt-5' style={{ alignItems: 'center' }}>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-4 mb-2'>Thời gian tắt</label>
              {/* end::Label */}
            </div>
            <div className='col-8'>
              {/* begin::Input */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker views={['hours', 'minutes', 'seconds']} value={endTime}
                  ampm={false} timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  className='form-control form-control-solid'
                  onChange={(newValue) => setEndTime(newValue)} />
              </LocalizationProvider>
              {new Date(beginTime) > new Date(endTime) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      Thời gian tắt phải sau thời gian bật
                    </span>
                  </div>
                </div>
              )}
              {/* end::Label */}
            </div>
          </div>
          <div className='row mt-5 mb-5' style={{ alignItems: 'center' }}>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-4 mb-2'>Cho phép hoạt động</label>
              {/* end::Label */}
            </div>
            <div className='col-4 div-logo-login'>
              <input className='input-radio' type="radio" id="active" name="status" value="active" checked={active} onClick={() => setActive(!active)} />
              <label htmlFor='active'>Hoạt động</label>
            </div>
            <div className='col-4 div-logo-login'>
              <input className='input-radio' type="radio" id="pause" name="status" value="pause" checked={!active} onClick={() => setActive(!active)} />
              <label htmlFor='pause'>Tạm dừng</label>
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center '>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-outline-primary me-3 cancel'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Quay lại
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched || beginTime > endTime}
          >
            <span className='indicator-label fw-b'>Cập nhật</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Vui lòng đợi...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export { ModalForm }
