import {SearchOutlined, SoundOutlined} from '@ant-design/icons'
import {Button, Col, Input, InputNumber, InputRef, Modal, Row, Slider, Space} from 'antd'
import 'antd/dist/antd.css'
import {ColumnType, ColumnsType} from 'antd/lib/table/interface'
import moment from 'moment'
import {useEffect, useMemo, useRef, useState} from 'react'
import Highlighter from 'react-highlight-words'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {KTCardBody, QUERIES, initialQueryState} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {TableComponent} from '../../../../../components'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {Role} from '../../../../modules/auth/core/_models'
import {UserGroupsPagination} from '../components/pagination/UserGroupsPagination'
import {useListView} from '../core/ListViewProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import {Address} from '../core/_models'
import {
  checkDeleteDeviceGroup,
  controlVolumeDeviceGroupInfo,
  deleteDeviceGroup,
} from '../core/_requests'
import {right} from '@popperjs/core'

const NhomThietBiTable = () => {
  const {setItemIdForUpdate} = useListView()
  const userGroups = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data: any = useMemo(() => userGroups, [userGroups])
  const {state} = useQueryRequest()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {refetch} = useQueryResponse()

  interface DataType {
    key: string
    Id: string
    DeviceGroupName: string
    Info: string
    ImeiMaster: number
    CreateTime: Date
    UpdateTime: Date
    CreateByUser: string
    DeviceType: number
    StreamState: any
    Longitude: number
    Latitude: number
    NguonID: string
    Address: Address
    Vol: number
  }

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  type DataIndex = keyof DataType
  const [FromTime, setFromTime] = useState<string | undefined>()
  const [ToTime, setToTime] = useState<string | undefined>()
  const [isValid, setIsValid] = useState<boolean>(true)
  const {updateState} = useQueryRequest()
  const {auth, currentUser} = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [inputValue, setInputValue] = useState(30)
  const [idDaiTruyenThanh, setIdDaiTruyenThanh] = useState('')

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }
  const DEVICE_GROUP_EDIT = currentUser?.Roles.find((item: Role) => {
    return item.Value === 15
  })
  // const DEVICE_GROUP_DELETE = currentUser?.Roles.find((item: Role) => {
  //   return item.Value === 16
  // })

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (record: DataType) => {
    setItemIdForUpdate(record.Id)
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate('')
  }

  const Fdelete = (record: DataType) => {
    // Swal.fire({
    //   title: "Bạn chắc chắn muốn xóa?",
    //   text: ' ',
    //   icon: "question",
    //   showCancelButton: true,
    //   confirmButtonText: "Có",
    //   cancelButtonText: "Không",
    //   confirmButtonColor: "red",
    // }).then(async function (result) {
    //   if (result.value) {
    //     await deleteItem.mutateAsync(record)
    //   }
    // })
    CheckdeleteItem.mutateAsync(record)
  }

  const CheckdeleteItem = useMutation((record: DataType) =>
    checkDeleteDeviceGroup(
      auth?.configs,
      record.Id || '',
      (res: any) => {
        Swal.fire({
          icon: 'error',
          title: res.data || 'Không tìm thấy dữ liệu',
          text: ' ',
          confirmButtonText: 'Đóng lại',
          width: '500',
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            cancel(true)
          }
        })
      },
      (id: any, message: any) => {
        Swal.fire({
          title: message,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Xác nhận',
          cancelButtonText: 'Hủy bỏ',
          confirmButtonColor: 'red',
          width: '500',
        }).then(async function (result) {
          if (result.value) {
            await deleteItem.mutateAsync(id)
          }
        })
      }
    )
  )

  const deleteItem = useMutation((id: string) => deleteDeviceGroup(auth?.configs, id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      Swal.fire({
        icon: 'success',
        title: 'Xóa thành công',
        text: ' ',
        showConfirmButton: false,
        timer: 1500,
      })
      cancel(true)
    },
    onError: (error: any) => {
      Swal.fire({
        icon: 'error',
        title: error.response.data || 'Xóa thất bại',
        text: ' ',
        confirmButtonText: 'Đóng lại',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          cancel(true)
        }
      })
    },
  })

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => updateFilter(dataIndex, selectedKeys[0]?.toString()?.trim() || '')}
          style={{marginBottom: 8, display: 'block'}}
        />
        <div className='row d-flex'>
          <div className='col-6'>
            <Button
              type='primary'
              onClick={() => updateFilter(dataIndex, selectedKeys[0]?.toString()?.trim() || '')}
              icon={<SearchOutlined />}
              size='small'
              className='w-100'
            >
              Tìm
            </Button>
          </div>
          <div className='col-6'>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters)
                updateFilter(dataIndex, '')
              }}
              size='small'
              className='w-100'
            >
              Làm mới
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const updateFilter = (dataIndex: any, value: any) => {
    let tmp: any = {
      ...(state.filter as object),
    }
    tmp[dataIndex] = value
    updateState({
      filter: tmp,
      ...initialQueryState,
    })
  }

  const getColumnSearchTime = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <div className='row d-flex'>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bold'>Từ ngày:</label>
            <Input
              ref={searchInput}
              style={{marginBottom: 8, display: 'block'}}
              type='date'
              placeholder='Từ ngày'
              name='FromTime'
              onChange={(e) => {
                if (ToTime && new Date(e.target.value) > new Date(ToTime)) setIsValid(false)
                else setIsValid(true)
                setFromTime(e.target.value)
              }}
              value={FromTime}
            />
          </div>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bold'>Đến ngày:</label>
            <Input
              ref={searchInput}
              style={{marginBottom: 8, display: 'block'}}
              type='date'
              placeholder='Đến ngày'
              name='ToTime'
              onChange={(e) => {
                if (FromTime && new Date(e.target.value) < new Date(FromTime)) setIsValid(false)
                else setIsValid(true)
                setToTime(e.target.value)
              }}
              value={ToTime}
            />
          </div>
        </div>
        <div className='row'>
          {!isValid && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  Đến ngày phải lớn hơn Từ ngày
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='row d-flex justify-content-center'>
          <div className='col-4'>
            <Button
              type='primary'
              onClick={() => {
                let tmp: any = {
                  ...(state.filter as object),
                }
                tmp['FromTime'] = FromTime ? `${FromTime}T00:00:00Z` : null
                tmp['ToTime'] = ToTime ? `${ToTime}T23:59:59Z` : null
                updateState({
                  filter: tmp,
                  ...initialQueryState,
                })
              }}
              icon={<SearchOutlined />}
              size='small'
              className='w-100'
            >
              Tìm
            </Button>
          </div>
          <div className='col-4'>
            <Button
              onClick={() => {
                setFromTime('')
                setToTime('')
                clearFilters && handleReset(clearFilters)
                let tmp: any = {
                  ...(state.filter as object),
                }
                tmp['FromTime'] = null
                tmp['ToTime'] = null
                updateState({
                  filter: tmp,
                  ...initialQueryState,
                })
              }}
              size='small'
              className='w-100'
            >
              Làm mới
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  if (!Array.isArray(data)) return <></>

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      key: 'index',
      render: (text, record, index) => index + 1 + (state.currentPage - 1) * state.pageSize,
      align: 'center',
      width: '5%',
      // fixed: 'left',
    },
    {
      title: 'Đài truyền thanh',
      dataIndex: 'DeviceGroupName',
      key: 'DeviceGroupName',
      ...getColumnSearchProps('DeviceGroupName'),
      width: '16%',
      // fixed: 'left',
    },
    {
      title: 'Mã định danh',
      dataIndex: 'NguonID',
      key: 'NguonID',
      ...getColumnSearchProps('NguonID'),
      width: '10%',
      // fixed: 'left',
    },
    // {
    //   title: 'Vị trí',
    //   dataIndex: 'Address',
    //   key: 'Address',
    //   className: 'col-UserGroupName white-space',
    //   // ...getColumnSearchProps('Address'),
    //   width: '12%',
    //   render: (a: Address) => {
    //     if (a)
    //       return (
    //         <span title={`${a.WardName}, ${a.DistrictName}, ${a.CityName}`}>
    //           {a.WardName}, {a.DistrictName}, {a.CityName}
    //         </span>
    //       )
    //   },
    // },
    {
      title: 'Imei bộ phát',
      dataIndex: 'ImeiMaster',
      key: 'ImeiMaster',
      ...getColumnSearchProps('ImeiMaster'),
      align: 'center',
      width: '13%',
    },
    {
      title: 'ÂM LƯỢNG',
      // dataIndex: 'Volumne',
      key: 'Vol',
      ...getColumnSearchProps('Vol'),
      // render: (Volumne: number) => (Volumne ? `${Volumne}%` : ''),
      width: '10%',
      render: (record: DataType) => {
        return (
          <Space>
            {record.Vol ? `${record.Vol}%` : '0'}

            <Button
              title={'Âm lượng'}
              type='text'
              icon={<SoundOutlined />}
              onClick={() => {
                setIsModalOpen(true)
                setIdDaiTruyenThanh(record.Id)
                setInputValue(record.Vol)
              }}
            />
          </Space>
        )
      },
    },
    // {
    //   title: 'Thông tin nhóm',
    //   dataIndex: 'Info',
    //   key: 'Info',
    //   className: 'col-UserGroupName white-space',
    //   ...getColumnSearchProps('Info'),
    //   width: '15%',
    //   render: (info) => {
    //     return <span title={info}>{info}</span>
    //   },
    // },
    {
      title: 'Người cập nhật',
      dataIndex: 'CreateByUser',
      key: 'CreateByUser',
      align: 'center',
      ...getColumnSearchProps('CreateByUser'),
      width: '10%',
    },
    {
      title: 'Thời gian cập nhật',
      dataIndex: 'UpdateTime',
      key: 'UpdateTime',
      ...getColumnSearchTime('UpdateTime'),
      align: 'center',
      render: (UpdateTime) => {
        return <p className='center m-0'>{moment(UpdateTime).format('DD-MM-YYYY HH:mm')}</p>
      },
      width: '10%',
    },
    {
      title: 'Hành động',
      key: 'Hành động',
      align: 'center',
      // fixed: 'right',
      render: (_, record) => (
        <Space size='middle'>
          {DEVICE_GROUP_EDIT && (
            <>
              <i
                className='bi bi-pencil-fill fs-1'
                onClick={() => {
                  openEditModal(record)
                }}
                style={{
                  cursor: 'pointer',
                  padding: '5px 7px',
                  color: '#1E2EC0',
                  background: '#E0F2FF',
                  borderRadius: '5px',
                }}
              ></i>
              <i
                className='bi bi-trash text-danger fs-1'
                onClick={() => {
                  Fdelete(record)
                }}
                style={{
                  cursor: 'pointer',
                  padding: '5px 7px',
                  background: '#FFE8EF',
                  borderRadius: '5px',
                }}
              ></i>
            </>
          )}
        </Space>
      ),
      width: '10%',
    },
  ]

  const handleCancelVolumne = () => {
    setIsModalOpen(false)
  }
  const onChange = (newValue: any) => {
    setInputValue(newValue)
  }

  const handleOk = () => {
    const payload = {
      Id: idDaiTruyenThanh,
      Volume: inputValue,
    }

    try {
      controlVolumeDeviceGroupInfo(
        auth?.configs,
        payload.Id || '',
        payload.Volume,
        (res: any) => {
          Swal.fire({
            icon: 'success',
            title: 'Cập nhật thông tin thành công',
            text: ' ',
            showConfirmButton: false,
            timer: 1500,
          }).then((_) => {
            refetch()
          })
          // cancel()
        },
        (message: any) => {
          Swal.fire({
            icon: 'error',
            title: 'Cập nhật thông tin thất bại',
            text: ' ',
            confirmButtonText: 'Đóng lại',
            confirmButtonColor: '#009ef7',
          })
        }
      )
    } catch (ex) {
      console.error(ex)
    } finally {
      refetch()
      setIsModalOpen(false)
    }
  }

  return (
    <KTCardBody className='py-4'>
      <TableComponent columns={columns} dataSource={data} />
      <UserGroupsPagination />
      {isLoading && <Loading />}

      <Modal
        className='nguoi-dung-body'
        title='Thay đổi âm lượng'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancelVolumne}
      >
        <Row className='al-center'>
          <Col span={20}>
            <Slider
              min={0}
              max={100}
              onChange={onChange}
              value={inputValue || 0}
              // tooltip={{open: true}}
            />
          </Col>
          <Col span={4}>
            <InputNumber min={0} max={100} value={inputValue || 0} onChange={onChange} />
          </Col>
        </Row>
      </Modal>
    </KTCardBody>
  )
}

export {NhomThietBiTable}
