import {CheckCircleOutlined} from '@ant-design/icons'
import {Col, Modal, Row} from 'antd'
import {ColumnsType, TableProps} from 'antd/lib/table'
import {FC, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {TableComponent} from '../../../../../../components'
import {useAuth} from '../../../../../modules/auth'
import {approvalDeviceNcc, getlistDeviceNcc} from '../../core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import Swal from 'sweetalert2'

interface Props {
  isModalSpeakerOpen: boolean
  handleCancel: () => void
}
const useGetSchedule = (configs: any, state: any) => {
  return useQuery<any, any>(['get_device_ncc', state], () => getlistDeviceNcc(configs, state), {
    keepPreviousData: true,
  })
}
const ThietBiNCCModal: FC<Props> = ({isModalSpeakerOpen, handleCancel}) => {
  const {auth} = useAuth()
  const {state} = useQueryRequest()

  const {data: dataSource} = useGetSchedule(auth?.configs, state)
  const [selectionType] = useState<'checkbox'>('checkbox')
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      key: 'index',
      render: (text, record, index) => index + 1 + (state.currentPage - 1) * state.pageSize,
      align: 'center',
      fixed: 'left',
      width: '5%',
    },
    {
      title: 'Tên thiết bị',
      key: 'Info',
      fixed: 'left',
      // ...getColumnSearchProps('Info'),
      // render: (record: DataType) => {
      //   return (
      //     <span
      //       className='TextTitle'
      //       style={{fontStyle: 'underline'}}
      //       title={record.Info}
      //       onClick={() => {
      //         if (DEVICE_EDIT) {
      //           openModal(record.Id)
      //         } else {
      //           Swal.fire({
      //             icon: 'error',
      //             title: 'Bạn không có quyền chỉnh sửa thiết bị',
      //             text: ' ',
      //             confirmButtonText: 'Đóng lại',
      //           })
      //         }
      //       }}
      //     >
      //       {record.Info}
      //     </span>
      //   )
      // },
      width: '12%',
    },
    {
      title: 'IMEI',
      dataIndex: 'Imei',
      key: 'Imei',
      width: '10%',
    },
    {
      title: 'Trạng thái phát',
      key: 'State',
      className: 'center white-space',
      align: 'center',
    },

    {
      title: 'ĐỊA BÀN PHÁT',
      dataIndex: 'GroupName',
      key: 'GroupName',
      width: 200,
    },
    {
      title: 'NHIỆT ĐỘ',
      dataIndex: 'TempAir',
      key: 'TempAir',
    },
  ]

  // rowSelection object indicates the need for row selection
  const rowSelection: TableProps<any>['rowSelection'] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  }

  const CheckapprovalItem = useMutation(
    async (selectedRowKeys: any) => {
      return approvalDeviceNcc(auth?.configs, selectedRowKeys)
    },
    {
      onSuccess: (data) => {
        setSelectedRowKeys([])
        Swal.fire({
          title: 'Thành công',
          text: 'Phê duyệt thiết bị thành công',
          icon: 'success',
          confirmButtonText: 'Đồng ý',
        })
      },
      onError: (error) => {
        Swal.fire({
          title: 'Lỗi',
          text: 'Có lỗi trong quá trình phê duyệt thiết bị',
          icon: 'error',
          confirmButtonText: 'Đồng ý',
        })
      },
    }
  )

  const approvalDevice = () => {
    CheckapprovalItem.mutateAsync(selectedRowKeys)
  }

  return (
    <Row gutter={[16, 16]} align='top'>
      <Col span={24}>
        {/* Responsive */}
        <Modal
          title='Danh sách thiết bị chờ phê duyệt'
          centered
          open={isModalSpeakerOpen}
          onCancel={() => {
            setSelectedRowKeys([])
            handleCancel()
          }}
          className='responsive-modal'
          width={1000}
          cancelText='Đóng'
          footer={null}
        >
          <div className='btn-end'>
            <button type='button' className='btn btn-primary me-3' onClick={approvalDevice}>
              <CheckCircleOutlined /> Phê duyệt thiết bị
            </button>
          </div>
          <TableComponent
            columns={columns}
            dataSource={dataSource?.Data}
            rowSelection={{type: selectionType, ...rowSelection}}
          />
        </Modal>
      </Col>
    </Row>
  )
}

export default ThietBiNCCModal
