import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { NhomThietBiListWrapper } from './nhomthietbi-list/NhomThietBiList'
const NhomThietBiBreadcrumbs: Array<PageLink> = [
  {
    title: 'Trang chủ',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Quản trị hệ thống',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Quản lý đài truyền thanh',
    path: '/quantrihethong/nhomthietbi/danhsach',
    isSeparator: false,
    isActive: true,
  },
]

const NhomThietBiPage = () => {
  document.title = "Quản lý đài truyền thanh"
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='danhsach'
          element={
            <>
              <PageTitle breadcrumbs={NhomThietBiBreadcrumbs}></PageTitle>
              <NhomThietBiListWrapper />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/quantrihethong/nhomthietbi/danhsach' />} />
    </Routes>
  )
}

export default NhomThietBiPage
