import {createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState} from 'react'
import {
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  ID,
} from '../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProviderBDT'
import {DeviceInfo} from './_models'

type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void

  itemIdForLed: DeviceInfo
  setItemIdForLed: Dispatch<SetStateAction<DeviceInfo>>
  isAllSelected: boolean
  disabled: boolean

  // selectedObject: DeviceInfo
  // setSelectedObject: Dispatch<SetStateAction<DeviceInfo>>

  itemIdForUpdateLed?: string
  setItemIdForUpdateLed: Dispatch<SetStateAction<string>>
  cityIdSelected?: string
  setCityIdSelected: Dispatch<SetStateAction<string>>
  itemIdForSettingLed?: string
  setItemIdForSettingLed: Dispatch<SetStateAction<string>>
}

const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  itemIdForLed: {},
  setItemIdForLed: () => {},

  isAllSelected: false,
  disabled: false,

  // selectedObject: {},
  // setSelectedObject: () => { },
  setItemIdForUpdateLed: () => {},
  setItemIdForSettingLed: () => {},
  setCityIdSelected: () => {},
}

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProviderBDT: FC = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForLed, setItemIdForLed] = useState<DeviceInfo>(initialListView.itemIdForLed)
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  // const [selectedObject, setSelectedObject] = useState<DeviceInfo>(initialListView.selectedObject)
  const [itemIdForUpdateLed, setItemIdForUpdateLed] = useState<string>(
    initialListView.itemIdForUpdateLed || ''
  )
  const [itemIdForSettingLed, setItemIdForSettingLed] = useState<string>(
    initialListView.itemIdForSettingLed || ''
  )
  const [cityIdSelected, setCityIdSelected] = useState<string>(initialListView.cityIdSelected || '')

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForLed,
        setItemIdForLed,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },

        // selectedObject,
        // setSelectedObject,
        itemIdForUpdateLed,
        setItemIdForUpdateLed,
        itemIdForSettingLed,
        setItemIdForSettingLed,
        cityIdSelected,
        setCityIdSelected,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListViewBDT = () => useContext(ListViewContext)

export {ListViewProviderBDT, useListViewBDT}
